import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@material-ui/core';

export default ({ segregations = { elements: [] } }) =>
  segregations.elements.length ? (
    <Card>
      <CardHeader title="Segregation boundaries"></CardHeader>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Class</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segregations.elements.map(boundary => (
              <TableRow key={boundary.id}>
                <TableCell padding="checkbox">{boundary.class}</TableCell>
                <TableCell>{boundary.id}</TableCell>
                <TableCell>{boundary.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  ) : (
    <Typography>
      There are no segregation boundaries in this software item.
    </Typography>
  );
