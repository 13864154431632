import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Tooltip,
  IconButton,
  Typography,
  Grid,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiCheck,
  mdiCommentProcessingOutline,
  mdiShieldCheck,
  mdiShapeOutline,
} from '@mdi/js';
import PackageInfo from '../SoftwareItemSoupCheck/PackageInfo';
import DecisionRecordDocument from '../SoftwareItemSoupCheck/DecisionRecordDescription';
import ActionButton from './ActionButton';
import { green, grey } from '@material-ui/core/colors';
import { RiskAnalysis } from './RiskAnalysis';
import { withNameSpace } from './AddedDependency';

export const ModifiedDependency = ({
  action,
  disabled,
  handledDeps,
  name,
  namespace,
  pullUrl,
  registryUrl,
  spec,
}) => {
  const theme = useTheme();

  const [safetyClass, setSafetyClass] = React.useState(
    spec.changes.modified.find((change) => change.name === name).class
  );
  const [open, setOpen] = React.useState(false);

  return (
    <Card
      id={withNameSpace(name, namespace)}
      style={{
        marginBottom: theme.spacing(1),
      }}
    >
      <CardHeader
        title={
          <Grid direction="row" container alignItems="center">
            <Typography
              variant="h5"
              style={{
                marginRight: theme.spacing(1),
              }}
            >
              {name}
            </Typography>
            {handledDeps && handledDeps[withNameSpace(name, namespace)] ? (
              <Icon
                title="Risk analysis performed successfuly"
                size={1}
                path={mdiShieldCheck}
                style={{
                  fill: green[500],
                }}
              />
            ) : (
              <Icon
                title="Risk analysis pending"
                size={1}
                path={mdiShieldCheck}
                style={{
                  fill: grey[500],
                }}
              />
            )}
          </Grid>
        }
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent>
        <PackageInfo dep={name} spec={spec} registryUrl={registryUrl} />
        <DecisionRecordDocument
          title="Decision record"
          description={
            spec.changes.modified.find((dep) => dep.name === name)
              .descriptionDiff
          }
          safetyClass={safetyClass}
        />
        <RiskAnalysis
          open={open}
          closeHandler={() => setOpen(false)}
          assignAction={(cls) => {
            setOpen(false);
            setSafetyClass(cls);
          }}
        ></RiskAnalysis>
      </CardContent>
      {disabled ||
      (handledDeps && handledDeps[withNameSpace(name, namespace)]) ? null : (
        <CardActions disableSpacing>
          <Tooltip title="Perform safety classification">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <Icon path={mdiShapeOutline} size={1}></Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Discuss description on pull request">
            <IconButton href={pullUrl} target="github">
              <Icon path={mdiCommentProcessingOutline} size={1} />
            </IconButton>
          </Tooltip>
          <div style={{ flexGrow: 1 }}></div>
          <ActionButton
            title="Accept usage rationale changes"
            path={mdiCheck}
            action={() =>
              action({
                adrId: spec.changes.modified.find(
                  (change) => change.name === name
                ).adrId,
                description: spec.compliance.items[name].description,
                safetyClass: safetyClass,
              })
            }
          />
          <div
            style={{
              width: '12px',
            }}
          ></div>
        </CardActions>
      )}
    </Card>
  );
};
