import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  useTheme,
  makeStyles
} from '@material-ui/core';

import remark from 'remark';
import remark2react from 'remark-react';
import { descriptionLens } from '../utils/lenses/software-items';

/**
 *
 * @param {import('@material-ui/core').Theme} theme
 */
const useStyles = theme => ({
  h1: {
    paddingBottom: theme.spacing(2)
  },
  paragraph: {
    paddingBottom: theme.spacing(1)
  }
});

const Content = ({ description }) => {
  const theme = useTheme();
  const classes = makeStyles(useStyles)(theme);

  return remark()
    .use(remark2react, {
      remarkReactComponents: {
        h1: ({ children }) => (
          <Typography variant="h4" className={classes.h1}>
            {children}
          </Typography>
        ),
        p: ({ children }) => (
          <Typography variant="body1" className={classes.paragraph}>
            {children}
          </Typography>
        )
      }
    })
    .processSync(description).contents;
};

const SofwareItemDescription = ({ item }) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }}
    >
      <CardHeader title={item.name} subheader="Software item description" />
      <CardContent>
        <Content description={descriptionLens.get(item)} />
      </CardContent>
    </Card>
  );
};

export default SofwareItemDescription;
