export const classLens = {
  get: item => {
    if (!item.parents) return;

    const parents = Object.keys(item.parents);

    return parents.length === 1 ? item.parents[parents[0]].class : undefined;
  }
};

export const descriptionLens = {
  get: item => {
    if (!item.parents) return;

    const parents = Object.keys(item.parents);

    return parents.length === 1
      ? item.parents[parents[0]].description
      : undefined;
  }
};

export const parentLens = {
  get: item => {
    if (!item.parents) return;

    const parents = Object.keys(item.parents);

    return parents.length === 1 ? parents[0] : undefined;
  }
};
