import React from 'react';
import { AddedDependency } from './AddedDependency';
import { ModifiedDependency } from './ModifiedDependency';
import { RemovedDependency } from './RemovedDependency';

const registryUrl = (dep, language) => {
  console.log(dep);
  switch (language) {
    case 'python':
      return `https://pypi.org/project/${dep}`;
    case 'javascript':
      return `https://npmjs.com/package/${dep}`;
    default:
      break;
  }
};

const RiskManagementActions = ({
  handledDeps,
  language,
  merged,
  pullUrl,
  spec,
  addAction,
  modifyAction,
  removeAction,
}) => {
  const deps = {};

  spec.changes.added.forEach((dep) => {
    if (
      spec.compliance &&
      spec.compliance.items &&
      spec.compliance.items[dep.name]
    ) {
      deps[dep.name] = {
        type: 'added',
      };

      return;
    }

    deps[dep.name] = {
      type: 'pending',
    };
  });

  spec.changes.modified.forEach((dep) => {
    deps[dep.name] = {
      type: 'modified',
    };
  });

  spec.changes.removed.forEach((dep) => {
    deps[dep.name] = {
      type: 'removed',
    };
  });

  return (
    <React.Fragment>
      {Object.keys(deps)
        .sort()
        .map((name) => {
          const dep = deps[name];

          switch (dep.type) {
            case 'added':
              return (
                <AddedDependency
                  disabled={merged}
                  action={addAction}
                  key={name}
                  name={name}
                  namespace={language}
                  spec={spec}
                  handledDeps={handledDeps}
                  pullUrl={pullUrl}
                  registryUrl={registryUrl(name, language)}
                />
              );
            case 'modified':
              return (
                <ModifiedDependency
                  disabled={merged}
                  action={modifyAction}
                  key={name}
                  name={name}
                  namespace={language}
                  spec={spec}
                  handledDeps={handledDeps}
                  pullUrl={pullUrl}
                  registryUrl={registryUrl(name, language)}
                />
              );
            case 'pending':
              return <div>{name}: Pending input from developer</div>;
            case 'removed':
              return (
                <RemovedDependency
                  disabled={merged}
                  action={removeAction}
                  key={name}
                  name={name}
                  namespace={language}
                  spec={spec}
                  handledDeps={handledDeps}
                  pullUrl={pullUrl}
                />
              );
            default:
              return <div>unhandled {dep.type}</div>;
          }
        })}
    </React.Fragment>
  );
};

export default RiskManagementActions;
