import { firestore } from '../stores/firebaseInit';

export const myTeamSetupUrl = () => '/setup/team';

export const softwareItemSetupUrl = (installationId, itemId) =>
  `/t/${installationId}/setup/software-item/${itemId}`;

export const softwareItemUrl = (id) =>
  `/software/items/${encodeURIComponent(id)}`;

export const softwareItemCheckUrl = (id) => `${softwareItemUrl(id)}/checks`;
export const softwareItemCheckUrl2 = async (installation_id, id) => {
  const installation = await firestore
    .collection('installations')
    .doc(installation_id)
    .get();

  if (installation.exists) {
    return `${softwareItemUrl(id)}/checks`;
  }

  return null;
};

export const teamArchitectureUrl = (installation) =>
  `/t/${installation}/architecture`;

export const teamArchitectureSetupUrl = (installation) =>
  `/t/${installation}/setup/architecture`;

export const teamOverviewUrl = (installation) => `/t/${installation}/overview`;
export const teamSettingsUrl = (installation) => `/t/${installation}/settings`;
export const teamProcessesUrl = (installation) =>
  `/t/${installation}/processes`;
export const teamReportsUrl = (installation) => `/t/${installation}/reports`;
export const teamTechnicalFilesUrl = (installation) =>
  `/t/${installation}/technical-files`;

export const teamSoftwareItemsUrl = (installation) =>
  `/t/${installation}/software-items`;

export const teamSoftwareUrl = (installation) => `/t/${installation}/software`;

export const teamReportUrl = (installation, id) =>
  `/t/${installation}/technical-files/${id}`;

export const teamSoftwareItemUrl = (installation, id) =>
  `/t/${installation}/software-items/${encodeURIComponent(id)}`;

export const teamSoftwareItemCheckUrl = (installation, id) =>
  `${teamSoftwareItemUrl(installation, id)}/checks`;

export const teamSoftwareItemPullsUrl = (installation, id) =>
  `${teamSoftwareItemUrl(installation, id)}/pulls`;

export const teamSoftwareItemPullUrl = (installation, id, pull) =>
  `${teamSoftwareItemUrl(installation, id)}/pulls/${pull}`;
