import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';

// @ts-ignore
import { navigate } from '@reach/router';

import itemShape from './shapes/item';
import { teamSoftwareItemUrl } from '../../../utils/urls';
import { parentLens } from '../../../utils/lenses/software-items';

const elementClass = element => {
  if (element.class) return element.class;

  const keys = Object.keys(element.parents);

  return element.parents[keys[0]].class;
};

export default class Backend {
  constructor(element) {
    this.element = element;
  }

  update = ({ elements, fit, teamId, zoom }) => {
    const g = new dagreD3.graphlib.Graph({}).setGraph({});

    elements.forEach(element => {
      const cls = elementClass(element);

      console.log(element);
      const label = `
        <div class="partitioning-viewer__name">${element.name}</div>
        <div class="partitioning-viewer__type">
          <span>«${element.type}»</span><br>
          <span>«class ${cls}»</span><br>
        </div>
        `;

      g.setNode(element.id, {
        label,
        labelType: 'html',
        shape: 'item',
        cls,
        onClick: () => navigate(teamSoftwareItemUrl(teamId, element.id))
      });
    });

    elements
      .filter(element => parentLens.get(element))
      .forEach(element => {
        g.setEdge(parentLens.get(element), element.id, {
          label: '',
          // curve: d3.curveStepAfter,
          arrowhead: 'vee'
        });
      });

    const svg = d3.select(this.element.current);
    // const inner = svg.select('g');

    const render = new dagreD3.render();

    render.shapes().item = itemShape;

    render(svg, g);
    const inner = d3.select('.output');

    const zoomHandler = d3.zoom().on('zoom', () => {
      inner.attr('transform', d3.event.transform);
    });

    if (zoom) {
      svg.call(zoomHandler);
    }

    const viewWidth = this.element.current.parentNode.getBoundingClientRect()
      .width;
    const viewHeight = ((viewWidth * 9) / 16) * 0.8;

    // console.log(viewWidth, viewHeight);

    svg.attr('width', viewWidth);
    svg.attr('height', viewHeight);

    const { height: graphHeight, width: graphWidth } = g.graph();

    // @ts-ignore
    const initialScale = fit ? viewWidth / graphWidth : 1;
    // console.log(initialScale);

    const renderWidth =
      // @ts-ignore
      (viewWidth - graphWidth * initialScale) / 2;
    const renderHeight =
      // @ts-ignore
      (viewHeight - graphHeight * initialScale) / 2;

    // console.log(renderHeight, renderWidth);

    svg.call(
      zoomHandler.transform,
      d3.zoomIdentity.translate(renderWidth, renderHeight).scale(initialScale)
    );
  };
}
