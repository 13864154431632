import React, { useEffect, useState } from 'react';
import Page from '../../components/Page2';

import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Container,
  useTheme,
} from '@material-ui/core';
import PackageViewer from '../../components/viewers/Package';

import SoftwareItemDescription from '../../components/SoftwareItemDescription';

import { firestore } from '../../stores/firebaseInit';
import DecisionRecords from './DecisionRecords';
import { useInstallation } from '../../stores/InstallationProvider';
import { StartConfigurationCard } from './StartConfigurationCard';
import PageHeader from '../../components/PageHeader';
import { PullsList } from '../SoftwareItemPulls/PullsList';
import SegregationsCard from './SegregationsCard';
import { featuresLens } from '../../utils/lenses/installation';

export const isManaged = (installation, softwareItemId) => {
  const softwareItemData = installation.data.elements[softwareItemId];

  return (
    softwareItemData && softwareItemData.configuration.state === 'completed'
  );
};

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(),
    textTransform: 'capitalize',
  },
});

const getScopedName = (item) =>
  item.location === 'external'
    ? `${item.technology.toLowerCase()}:${item.name}`
    : item.name;

const SoftwareItem = ({
  classes,
  installationId,
  softwareItemId,
  tabValue,
}) => {
  const theme = useTheme();
  const [state, setState] = useState({
    loading: true,
    handled: [],
    isManaged: false,
    item: null,
  });

  const [installation, actions] = useInstallation();
  actions.init(installationId);

  if (installation.data) {
    console.log(installation.data.elements);
  }

  useEffect(() => {
    firestore
      .collection('installations')
      .doc(installationId)
      .collection('software-items')
      // .where('parent', '==', softwareItemId)
      .get()
      .then((snap) =>
        setState((state) => ({
          ...state,
          handled: snap.docs
            .filter((item) => {
              const itemData = item.data();

              return itemData.parents
                ? itemData.parents[softwareItemId] !== undefined
                : false;
            })
            .map((item) => item.data().id)
            // NOTE: removes namespace to match existing software items in Firestore with what is in the spec
            .map((id) => {
              const nsFragments = id.split(':');

              return nsFragments[nsFragments.length - 1];
            }),
        }))
      );

    firestore
      .collection('installations')
      .doc(installationId)
      .collection('software-items')
      .doc(softwareItemId)
      .get()
      .then((doc) => {
        setState((state) => ({
          ...state,
          loading: false,
          isManaged: true,
          item: doc.data(),
        }));
      });
  }, [installationId, softwareItemId]);

  if (!installation.data || !state.item)
    return (
      <Page
        team={installationId}
        key={softwareItemId}
        title={`Software item ${softwareItemId} - CompliancePal console`}
      >
        <div></div>
      </Page>
    );

  if (isManaged(installation, softwareItemId)) {
    const tabs = [
      'Overview',
      'Diagram',
      'Segregations',
      'Decisions',
      'Pulls',
      'Interfaces',
    ];

    const disabledFeatures = featuresLens.get(installation);

    // disabled.push('Segregations');

    const filteredTabs = tabs.filter(
      (name) => !disabledFeatures.includes(name)
    );

    const tabIs = (index, feature) => filteredTabs[index] === feature;

    const segregations = disabledFeatures.includes('Segregations')
      ? undefined
      : installation.data.architecture.spec.elements.find(
          (element) => element.id === softwareItemId
        ).segregations;

    return (
      <Page
        team={installationId}
        key={softwareItemId}
        title={`Software item ${softwareItemId} - CompliancePal console`}
      >
        <PageHeader
          title={`Software items - ${state.item.name}`}
          tabs={filteredTabs}
          value={tabValue}
        />
        <Container>
          {tabIs(tabValue, 'Overview') && (
            <SoftwareItemDescription
              style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
              item={state.item}
            />
          )}
          {tabIs(tabValue, 'Diagram') && (
            <Card className={classes.card}>
              <CardHeader
                title="Code diagram"
                subheader="Includes soup dependencies used by the component"
              />
              <CardContent>
                <PackageViewer
                  spec={installation.data.elements[softwareItemId].spec.data}
                  manifest={
                    installation.data.elements[softwareItemId].spec.manifest
                  }
                  name={
                    installation.data.elements[softwareItemId].spec.manifest
                      .name || softwareItemId
                  }
                  language={
                    installation.data.elements[softwareItemId].configuration
                      .language
                  }
                  handled={state.handled}
                  compact
                  fit
                  boundaries={segregations}
                />
              </CardContent>
            </Card>
          )}
          {tabIs(tabValue, 'Decisions') && (
            <DecisionRecords
              installationId={installationId}
              softwareItemId={softwareItemId}
            />
          )}
          {tabIs(tabValue, 'Pulls') && (
            <Card className={classes.card}>
              <CardContent>
                <PullsList
                  installationId={installationId}
                  softwareItemId={softwareItemId}
                  state="open"
                />
              </CardContent>
            </Card>
          )}
          {
            // features behind flags
          }
          {tabIs(tabValue, 'Segregations') && (
            <SegregationsCard segregations={segregations} />
          )}
          {tabIs(tabValue, 'Interfaces') && <div>Interfaces documentation</div>}
        </Container>
      </Page>
    );
  }

  return (
    <Page
      team={installationId}
      key={softwareItemId}
      title={`Software item ${softwareItemId} - CompliancePal console`}
    >
      <PageHeader
        title={`Software items - ${getScopedName(state.item)}`}
        tabs={['Overview', 'Configure']}
        value={tabValue}
      />
      <Container>
        {tabValue === 0 && (
          <SoftwareItemDescription className={classes.card} item={state.item} />
        )}
        {tabValue === 1 && state.item.location !== 'external' && (
          <StartConfigurationCard
            classes={classes}
            installationId={installationId}
            softwareItemId={softwareItemId}
          />
        )}
      </Container>
    </Page>
  );
};

export default withStyles(styles)(SoftwareItem);
