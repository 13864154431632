import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from 'mdi-material-ui/ChevronLeft';
import ChevronRightIcon from 'mdi-material-ui/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Brand from '../Brand';
import { Button, Tooltip, ListItemAvatar, Avatar } from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiGithubCircle,
  mdiHome,
  mdiStateMachine,
  mdiFileCadBox,
  mdiFileCabinet,
  mdiCodeBracesBox,
  mdiSettings
} from '@mdi/js';

import { auth, firebase } from '../../stores/firebaseInit';
import { navigate, Link, redirectTo } from '@reach/router';
import ProfileMenu from './ProfileMenu';
import {
  teamOverviewUrl,
  teamArchitectureUrl,
  teamProcessesUrl,
  teamSettingsUrl,
  teamTechnicalFilesUrl,
  teamSoftwareItemsUrl
} from '../../utils/urls';
import { useUiContext } from '../../stores/UiProvider';
import { useProfile } from '../../stores/profileProvider';
import ErrorBoundary from '../ErrorBoundary';
import Helmet from 'react-helmet';

import { useInstallation } from '../../stores/InstallationProvider';
import { TodosMenu } from './TodosMenu';

const menuItems = team => [
  {
    href: teamOverviewUrl(team),
    path: mdiHome,
    text: 'Home'
  },
  {
    href: teamSettingsUrl(team),
    path: mdiSettings,
    text: 'Settings',
    divider: true,
    role: 'owners'
  },
  {
    href: teamProcessesUrl(team),
    path: mdiStateMachine,
    text: 'Processes',
    divider: true
  },
  {
    href: teamArchitectureUrl(team),
    path: mdiFileCadBox,
    text: 'Architecture'
  },
  {
    href: teamSoftwareItemsUrl(team),
    path: mdiCodeBracesBox,
    text: 'Software items',
    divider: true
  },
  {
    href: teamTechnicalFilesUrl(team),
    path: mdiFileCabinet,
    text: 'Technical files',
    divider: true
  }
  /*
  {
    href: teamReportsUrl(team),
    path: mdiFilePdf,
    text: 'Reports',
  }
  */
];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    //width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  github: {
    marginRight: theme.spacing()
  },
  button: {
    marginLeft: theme.spacing()
  }
}));

/**
 *
 * @param {object} props
 * @param {object} props.children
 * @param {string} [props.team]
 * @param {string} [props.title]
 */
export function Page({ children, team, title }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [{ openNavigationDrawer }, actions] = useUiContext();
  const [teamProfile, setTeamProfile] = useState(null);

  const [profile, { getInstallationInfo }] = useProfile();
  const [installation, { init }] = useInstallation();
  init(team);

  useEffect(() => {
    profile && team && setTeamProfile(getInstallationInfo(team));
  }, [getInstallationInfo, profile, team]);

  const handleDrawerOpen = () => {
    actions.openNavigationDrawer();
  };

  const handleDrawerClose = () => {
    actions.closeNavigationDrawer();
  };

  const handleLoginClick = async () => {
    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await auth.signInWithPopup(new firebase.auth.GithubAuthProvider());

      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(installation.data, profile);
  }, [installation, profile]);

  return (
    <div className={classes.root}>
      <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openNavigationDrawer
        })}
      >
        {teamProfile ? (
          <Helmet>
            <title>{`${teamProfile.account} - ${title}`}</title>
          </Helmet>
        ) : null}
        <Toolbar>
          <Brand></Brand>
          <div
            style={{
              flexGrow: 1
            }}
          />
          {team && <TodosMenu />}
          {auth.currentUser ? (
            <ProfileMenu
              handleLogoutClick={() => {
                auth.signOut().then(() => redirectTo('/'));
              }}
            />
          ) : (
            <Button
              onClick={handleLoginClick}
              color="primary"
              variant="outlined"
              className={classes.button}
            >
              <Icon
                size={1}
                path={mdiGithubCircle}
                className={classes.github}
                color={theme.palette.primary.main}
              />
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {team && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openNavigationDrawer,
            [classes.drawerClose]: !openNavigationDrawer
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openNavigationDrawer,
              [classes.drawerClose]: !openNavigationDrawer
            })
          }}
        >
          <div className={classes.toolbar}></div>
          <List>
            {openNavigationDrawer ? (
              <ListItem divider>
                <ListItemAvatar>
                  <Avatar
                    src={teamProfile && teamProfile.avatar}
                    style={{
                      width: 24,
                      height: 24
                    }}
                  ></Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={teamProfile ? teamProfile.account : ''}
                />
              </ListItem>
            ) : (
              <ListItem divider>
                <Tooltip
                  title={`Team: ${teamProfile && teamProfile.account}`}
                  placement="right"
                >
                  <ListItemAvatar>
                    <Avatar
                      src={teamProfile && teamProfile.avatar}
                      style={{
                        width: 24,
                        height: 24
                      }}
                    ></Avatar>
                  </ListItemAvatar>
                </Tooltip>
              </ListItem>
            )}
            {menuItems(team).map(({ divider, href, path, role, text }) => {
              // console.log(role, installation, profile);
              return (
                <ListItem
                  disabled={
                    role
                      ? profile &&
                        installation.data &&
                        installation.data.__abac &&
                        installation.data.__abac[role][profile.uid]
                        ? false
                        : true
                      : false
                  }
                  button
                  component={Link}
                  key={text}
                  divider={divider}
                  to={href || ''}
                >
                  {openNavigationDrawer ? (
                    <React.Fragment>
                      <ListItemIcon>
                        <Icon
                          size={1}
                          color={theme.palette.grey[600]}
                          path={path}
                        ></Icon>
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </React.Fragment>
                  ) : (
                    <Tooltip title={text} placement="right">
                      <ListItemIcon>
                        <Icon
                          size={1}
                          color={theme.palette.grey[600]}
                          path={path}
                        ></Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                </ListItem>
              );
            })}
          </List>
          <div style={{ backgroundColor: 'white', flexGrow: 1 }} />
          <Divider />
          {openNavigationDrawer ? (
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
          ) : (
            <List>
              <ListItem button onClick={handleDrawerOpen}>
                <ListItemIcon>
                  <ChevronRightIcon
                  // @ts-ignore
                  // edge="end"
                  />
                </ListItemIcon>
                <ListItemText />
              </ListItem>
            </List>
          )}
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar}></div>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
}

export default Page;
