import React from 'react';
import { withStyles } from '@material-ui/core';

import Backend from './backend';

import './styles.css';

const styles = theme => ({
  layout: {
    padding: theme.spacing(),
    border: '1px solid whitesmoke',
    width: '100%',
    fontFamily: 'Arial'
  }
});

class PartitioningViewer extends React.Component {
  svg = React.createRef();

  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }

  update(event) {
    // @ts-ignore
    this.backend.update(this.props);
  }

  componentDidMount() {
    this.backend = new Backend(this.svg);
    this.update();

    window.addEventListener('resize', this.update);
  }

  componentDidUpdate() {
    this.update();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <svg ref={this.svg} className="partitioning-viewer"></svg>
      </div>
    );
  }
}

export default withStyles(styles)(PartitioningViewer);
