import React from 'react';

import Page from '../../components/Page2';
import { withStyles, Container } from '@material-ui/core';
import Uninstall from './Uninstall';
import Repositories from './Repositories';
import TeamMembership from './TeamMembership';
import PageHeader from '../../components/PageHeader';
import { useInstallation } from '../../stores/InstallationProvider';

const tabs = ['Collaborators', 'Repositories', 'Setup'];

/**
 *
 * @param {import('@material-ui/core').Theme} theme
 */
const styles = theme => ({
  avatar: {
    marginRight: theme.spacing(1)
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const Team = ({ classes, installationId, tabValue }) => {
  const [installation, { init }] = useInstallation();
  init(installationId);

  return (
    <Page team={installationId} title="Settings - CompliancePal console">
      <PageHeader title="Settings" tabs={tabs} value={tabValue} />
      {installation.data ? (
        <Container>
          {tabValue === 0 && (
            <TeamMembership
              classes={classes}
              installation={installation.data}
            />
          )}
          {tabValue === 1 && <Repositories classes={classes} />}
          {tabValue === 2 && (
            <Uninstall
              classes={classes}
              href={installation.data.meta.html_url}
            />
          )}
        </Container>
      ) : null}
    </Page>
  );
};

export default withStyles(styles)(Team);
