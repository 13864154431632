import React, { useState, useEffect } from 'react';

import Page from '../components/Page2';
import SoftwareItemsCard from '../components/cards/SoftwareItems';
import { useInstallation } from '../stores/InstallationProvider';
import { firestore } from '../stores/firebaseInit';
import PageHeader from '../components/PageHeader';
import { Container } from '@material-ui/core';

const tabs = ['Catalog', 'Decomposition'];

const SoftwareItems = ({ installation_id, tabValue }) => {
  const [installation] = useInstallation();

  const [items, setItems] = useState([]);

  useEffect(() => {
    return firestore
      .collection('installations')
      .doc(installation_id)
      .collection('software-items')
      .onSnapshot(snap =>
        setItems(
          snap.docs
            .map(doc => doc.data())
            .map(data => ({
              ...data,
              isSoup: data.location === 'external',
              isManaged: () => {
                try {
                  return installation.data.elements[data.id].spec && true;
                } catch (e) {
                  return false;
                }
              }
            }))
        )
      );
  }, [installation_id, installation]);

  return (
    <Page team={installation_id} title="Software Items - CompliancePal console">
      <PageHeader title="Software items" tabs={tabs} value={tabValue} />
      <Container>
        <SoftwareItemsCard
          items={items}
          teamId={installation_id}
          variant={tabValue === 0 && 'list'}
        />
      </Container>
    </Page>
  );
};

export default SoftwareItems;
