import dagreD3 from 'dagre-d3';

export default (parent, bbox, node) => {
  const { height, width } = bbox;

  const shape = parent.insert('rect', ':first-child')
    .attr('x', -width * 0.5)
    .attr('y', -height * 0.5)
    .attr('width', 1 * width)
    .attr('height', 1 * height);

  parent.classed('item', true);
  parent.on('click', node.onClick);

  node.intersect = point => dagreD3.intersect.rect(node, point);

  return shape;
}
