import dagreD3 from 'dagre-d3';

import React from 'react';
import ReactDOM from 'react-dom';
import { TooltipLabel } from './TooltipLabel';

export default (parent, bbox, node) => {
  const { height, width } = bbox;
  const radius = 20;

  const body = parent
    .insert('rect', ':first-child')
    .attr('rx', 5)
    .attr('ry', 5)
    .attr('x', -width * 0.5)
    .attr('y', -height * 0.5)
    .attr('width', 1 * width)
    .attr('height', 1 * height);

  // head
  parent
    .insert('circle')
    .attr('r', radius)
    .attr('cy', -height / 2 - radius + 5);

  // parent.classed('person', true);
  parent.style('fill', 'lightblue');
  parent.style('stroke', '#333');

  if (node.external) {
    // parent.classed('external-person', true);
    parent.style('fill', 'lightgrey');
  }

  bbox.height = parent.node().getBBox().height;

  parent.on('mouseenter', ev => {
    ReactDOM.hydrate(
      <TooltipLabel title={node.description || ''} name={node.name} open />,
      document.getElementById(`element-${node.id}`)
    );
  });

  parent.on('mouseleave', () => {
    ReactDOM.hydrate(
      <TooltipLabel
        title={node.description || ''}
        name={node.name}
        open={false}
      />,
      document.getElementById(`element-${node.id}`)
    );
  });

  node.intersect = point => dagreD3.intersect.rect(node, point);

  return body;
};
