import React from 'react';
import {
  Avatar,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import remark from 'remark';
import remark2react from 'remark-react';
import emoji from 'remark-emoji';
import { parseDiff, hastHandlers } from './diff-parser';
import { red, green } from '@material-ui/core/colors';

//NOTE: Diff colors inspired by https://github.com/davidar/pandiff

/**
 *
 * @param {import('@material-ui/core/styles').Theme} theme
 */
const styles = (theme) => ({
  code: {
    backgroundColor: 'lightgray',
    padding: 2,
    borderRadius: theme.spacing(0.25),
  },
  descriptionHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    borderTop: '1px solid gainsboro',
    paddingTop: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  added: {
    textDecoration: 'underline',
    color: green[900],
    backgroundColor: green[50],
  },
  removed: {
    textDecoration: 'line-through',
    color: red[900],
    backgroundColor: red[50],
  },
  safety: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

/**
 *
 * @param {object} options
 * @param {string} [options.description]
 * @param {string} options.safetyClass
 * @param {string} options.title
 */
const DecisionRecordDescription = ({ description, safetyClass, title }) => {
  const classes = makeStyles(styles)(useTheme());

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Typography variant="h6">{title}</Typography>
        <Tooltip
          title={
            safetyClass
              ? 'Safety classification'
              : 'Safety Classification not assigned'
          }
        >
          <Avatar className={classes.safety}>
            {safetyClass ? safetyClass : '?'}
          </Avatar>
        </Tooltip>
      </Grid>

      <div className={classes.description}>
        {description ? (
          remark()
            .use(emoji)
            .use(parseDiff)
            .use(remark2react, {
              sanitize: false,
              toHast: {
                commonmark: true,
                handlers: hastHandlers,
              },
              remarkReactComponents: {
                h1: ({ children }) => (
                  <Typography
                    variant="h5"
                    className={classes.descriptionHeader}
                  >
                    {children}
                  </Typography>
                ),
                h2: ({ children }) => (
                  <Typography
                    variant="h6"
                    className={classes.descriptionHeader}
                  >
                    {children}
                  </Typography>
                ),
                p: ({ children }) => {
                  return (
                    <Typography variant="body1" className={classes.paragraph}>
                      {children}
                    </Typography>
                  );
                },
                code: ({ children }) => (
                  <code className={classes.code}>{children}</code>
                ),
                added: ({ children }) => (
                  <Typography
                    display="inline"
                    variant="inherit"
                    className={classes.added}
                  >
                    {children}
                  </Typography>
                ),
                removed: ({ children }) => {
                  return (
                    <Typography
                      display="inline"
                      variant="inherit"
                      className={classes.removed}
                    >
                      {children}
                    </Typography>
                  );
                },
              },
            })
            .processSync(description).contents
        ) : (
          <Typography color="textSecondary" variant="body1">
            Commiter has been instructed to provide the decision rationale for
            using this SOUP component
          </Typography>
        )}
      </div>
    </React.Fragment>
  );
};

export default DecisionRecordDescription;
