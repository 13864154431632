import React, { useState, useEffect, useContext, useCallback } from 'react';
import { createContext } from 'react';
import { firestore, auth, analytics } from './firebaseInit';

export const ProfileContext = createContext(null);

export const ProfileProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      setUid(auth.currentUser.providerData[0].uid);
    }

    const disposer = auth.onAuthStateChanged(user => {
      if (user) {
        setUid(user.providerData[0].uid);
        analytics.setUserId(user.uid);
      } else {
        uid && setUid(null);
      }

      setLoading(false);
    });

    return () => disposer();
  }, [uid]);

  useEffect(() => {
    let disposer;

    if (uid) {
      disposer = firestore
        .collection('profiles')
        .doc(uid)
        .onSnapshot(snap => {
          const profile = snap.data();
          console.log(profile);

          setState(profile);
        });

      return disposer;
    } else {
      setState(null);
    }
  }, [uid]);

  const getInstallationInfo = useCallback(
    installationId =>
      state
        ? state.installations.find(
            installation => installation.id.toString() === installationId
          )
        : null,
    [state]
  );

  return (
    <ProfileContext.Provider value={[state, { getInstallationInfo }]}>
      {loading ? null : children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
