import React from 'react';
import Page from '../components/Page2';
import PageHeader from '../components/PageHeader';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button
} from '@material-ui/core';

const processes = [
  {
    title: 'Architecture management',
    description: `Architecture management tracks the evolution of your software system. Over time, new items are added, decomposed into smaller components, or removed. CompliancePal maintains records for each such events, tracing the rationale behind each change set.
`
  },
  {
    title: 'SOUP management',
    description: `Soup management automatically tracks the SOUP components used by your software items. CompliancePal maintains decision records that capture the rationale for using each SOUP component.`
  },
  {
    title: 'Classification tracking',
    description:
      'Safety classification management allows the ComplianceOfficer to assign the risk class of each software item, and checks that the there are no violations against the software decomposition.'
  }
];

export default ({ installationId }) => (
  <Page team={installationId} title="Processes - CompliancePal console">
    <PageHeader title="Processes" tabs={['Catalog']} />
    <Container>
      <Grid container spacing={3}>
        {processes.map(p => (
          <Grid item xs={4}>
            <Card key={p.title}>
              <CardHeader title={p.title}></CardHeader>
              <CardContent>
                <Typography align="justify">{p.description}</Typography>
              </CardContent>
              <CardActions>
                <Button disabled>More</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Page>
);
