import React, { useEffect, Fragment } from 'react';
import {
  IconButton,
  Badge,
  useTheme,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  ListItemAvatar
} from '@material-ui/core';
import { mdiInbox, mdiSourcePull } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { useInstallation } from '../../stores/InstallationProvider';
import { Link } from '@reach/router';
import { teamSoftwareItemPullUrl } from '../../utils/urls';

export const TodosMenu = () => {
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  const [installation] = useInstallation();

  useEffect(() => {
    console.log(installation);

    if (installation && installation.pulls) {
      setItems(installation.pulls);
    } else {
      setItems([]);
    }
  }, [installation]);

  return (
    <Fragment>
      <IconButton
        aria-controls="menu-todos"
        disabled={items.length === 0}
        style={{
          marginRight: theme.spacing(1)
        }}
        onClick={event => {
          setOpen(true);
          setAnchor(event.currentTarget);
        }}
      >
        <Badge color="primary" badgeContent={items.length || null}>
          <Icon
            size={1}
            path={mdiInbox}
            color={
              items.length === 0
                ? theme.palette.text.disabled
                : theme.palette.text.primary
            }
          />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchor}
        onClose={() => {
          setAnchor(null);
          setOpen(false);
        }}
        open={open}
        id="menu-todos"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography align="center" gutterBottom>
          Open tasks
        </Typography>
        <Divider />
        <List dense>
          {items.map((item, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={teamSoftwareItemPullUrl(
                installation.id,
                item.softwareItem,
                item.id
              )}
              onClick={() => {
                setAnchor(null);
                setOpen(false);
              }}
            >
              <ListItemAvatar>
                <Icon size={1} path={mdiSourcePull} />
              </ListItemAvatar>
              <ListItemText
                primary={item.title}
                secondary={item.softwareItem}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Fragment>
  );
};
