import React from 'react';
import {
  Button,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  withStyles,
  TextField
} from '@material-ui/core';
import JavascriptIcon from 'mdi-material-ui/LanguageJavascript';
import PythonIcon from 'mdi-material-ui/LanguagePython';
import NodejsIcon from 'mdi-material-ui/Nodejs';
import ReactIcon from 'mdi-material-ui/React';

import { functions } from '../../stores/firebaseInit';
import fsm from 'fsm-as-promised';

fsm.callbackPrefix = '';

const styles = theme => ({
  actionsContainer: {
    paddingTop: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(),
    marginRight: theme.spacing()
  },
  applicationPreset: {
    margin: theme.spacing()
  }
});

const presets = [
  {
    name: 'node',
    language: 'javascript',
    description: 'NodeJS application',
    icon: <NodejsIcon color="action" />
  },
  /*
  {
    name: 'firebase',
    description: 'Firebase application',
    icon: (<FirebaseIcon color="action" />)
  },
  */
  {
    name: 'react-cra',
    language: 'javascript',
    description: 'React application managed by Create React Application',
    icon: <ReactIcon color="action" />
  },
  {
    name: 'python-experimental',
    language: 'python',
    description: 'Python flask application (experimental)',
    icon: null
  }
  /*
  {
    name: 'react',
    description: 'React application (best effort)',
    icon: (<ReactIcon color="action" />)
  },
  */
];

class ConfigureSourceCard extends React.Component {
  state = {
    activeStep: 0,
    path: '',
    branch: ''
  };

  fsm = fsm.create({
    initial: 'initial',
    final: ['completed', 'cancelled'],
    events: [
      {
        name: 'mount',
        from: 'initial',
        to: ['Repository', 'PullRequest'],
        condition: () => {
          const { configuration } = this.props;

          if (configuration === undefined) return 0;
          console.log('FSM', configuration);

          return configuration.state === 'pull-request-opened' ? 1 : 0;
        }
      },
      { name: 'selectRepository', from: 'Repository' },
      { name: 'next', from: 'Repository', to: 'Preset' },
      { name: 'selectPreset', from: 'Preset' },
      { name: 'back', from: 'Preset', to: 'Repository' },
      { name: 'next', from: 'Preset', to: 'Branch' },
      { name: 'branchChange', from: 'Branch' },
      { name: 'back', from: 'Branch', to: 'Preset' },
      { name: 'next', from: 'Branch', to: 'Path' },
      { name: 'pathChange', from: 'Path' },
      { name: 'back', from: 'Path', to: 'Branch' },
      { name: 'configure', from: 'Path', to: 'Pending' },
      { name: 'updatePending', from: 'Pending', to: 'PullRequest' },
      { name: 'view', from: 'PullRequest' },
      {
        name: 'updatePullRequest',
        from: 'PullRequest',
        to: ['PullRequestSuccess', 'PullRequestError'],
        condition: ({ args }) => {
          const [props] = args;
          console.log('update-condition', props);

          const { configuration } = this.props;
          console.log('FSM', configuration.state);

          switch (configuration.state) {
            case 'completed':
              return 0;
            case 'failed':
            case 'cancelled':
              return 1;
            default:
              throw Error('Invalid state from server');
          }
        }
      },
      { name: 'restart', from: 'PullRequestError', to: 'Repository' },
      { name: 'cancel', from: 'PullRequestError', to: 'cancelled' },
      { name: 'done', from: 'PullRequestSuccess', to: 'completed' }
    ],
    callbacks: {
      enteredBranch: () => {
        this.setState({
          activeStep: 2
        });
      },
      enteredPath: () => {
        this.setState({
          activeStep: 3
        });
      },
      enteredPending: () => {
        this.setState({
          activeStep: 4,
          stepDescription: 'Wait for pull request to be opened'
        });
      },
      enteredPreset: () => {
        this.setState({
          activeStep: 1
        });
      },
      enteredRepository: () => {
        this.setState({
          activeStep: 0
        });
      },
      enteredPullRequest: () => {
        const { configuration } = this.props;

        this.setState({
          activeStep: 4,
          stepDescription:
            'View the pull request on GitHub. Merge to finish configuration or close to cancel',
          pullRequestHref: configuration.pull_request.href
        });
      },
      enteredPullRequestError: () => {
        this.setState({
          activeStep: 5,
          cancelled: true
        });
      },
      enteredPullRequestSuccess: () => {
        this.setState({
          activeStep: 4
        });
      },
      enteredcancelled: () => {
        this.props.cancelHandle();
      },
      leave: ({ name }) => console.log(`Leaving state ${name}`),
      leavePending: () => {
        const { item, configuration } = this.props;

        if (configuration === undefined) {
          throw new Error(`Software item ${item.id} is not yet configured`);
        }

        if (!['pull-request-opened'].includes(configuration.state)) {
          throw new Error('State change from server not white listed');
        }
      },
      leavePullRequest: ({ name }) => {
        const { configuration } = this.props;

        console.log(configuration, name);

        if (!['completed', 'cancelled'].includes(configuration.state)) {
          throw new Error('State change from server not white listed');
        }
      },
      branchChange: ({ args }) => {
        const [value] = args;

        this.setState({
          branch: value
        });
      },
      configure: async () => {
        const { installation_id, repositories, item } = this.props;

        const [owner, repo] = repositories[
          this.state.repoSelected
        ].full_name.split('/');

        await functions.httpsCallable(
          'httpsOnCallSoftwareItemsCreateOnboardingBranch'
        )({
          installation_id,
          owner,
          repo,
          element_id: item.id,
          branch: this.state.branch === '' ? 'master' : this.state.branch,
          path: this.state.path === '' ? '.' : this.state.path,
          preset: presets[this.state.presetSelected].name,
          language: presets[this.state.presetSelected].language
        });
      },
      pathChange: ({ args }) => {
        const [value] = args;

        this.setState({
          path: value
        });
      },
      restart: () => {
        this.setState({
          ...this.state,
          repoSelected: undefined,
          presetSelected: undefined,
          path: '',
          cancelled: false
        });
      },
      selectPreset: ({ args }) => {
        const [, index] = args;
        const { presetSelected } = this.state;

        if (presetSelected === undefined) {
          return this.setState({
            ...this.state,
            presetSelected: index,
            disabled: false
          });
        }

        if (presetSelected === index) {
          return this.setState({
            ...this.state,
            presetSelected: undefined,
            disabled: true
          });
        }

        this.setState({
          ...this.state,
          presetSelected: index,
          disabled: false
        });
      },
      selectRepository: ({ args }) => {
        const [, index] = args;
        const { repoSelected } = this.state;

        if (repoSelected === undefined) {
          return this.setState({
            repoSelected: index,
            disabled: false
          });
        }

        if (repoSelected === index) {
          return this.setState({
            repoSelected: undefined,
            disabled: true
          });
        }

        this.setState({
          repoSelected: index,
          disabled: false
        });
      }
    }
  });

  componentWillMount = this.fsm.mount;

  componentDidUpdate = props => {
    this.fsm.can('updatePending') && this.fsm.updatePending(props);
    this.fsm.can('updatePullRequest') &&
      this.fsm.updatePullRequest(props).catch(error => console.log(error));
  };

  isRepoSelected = index => this.state.repoSelected === index;

  isPresetSelected = index => this.state.presetSelected === index;

  reposTable = () => {
    const { repositories } = this.props;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell padding="none">Repository</TableCell>
            <TableCell>Private</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {repositories.map((repo, index) => {
            const isSelected = this.isRepoSelected(index);

            return (
              <TableRow
                hover
                onClick={event => this.fsm.selectRepository(event, index)}
                selected={isSelected}
                key={repo.full_name}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isSelected} />
                </TableCell>
                <TableCell padding="none">{repo.full_name}</TableCell>
                <TableCell>{repo.private ? 'yes' : 'no'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  presetsTable = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell padding="none" />
          <TableCell>Description</TableCell>
          <TableCell>Lang</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {presets.map((preset, index) => {
          const isChecked = this.state.presetSelected === index;

          const languageSwitch = () => {
            switch (preset.language) {
              case 'javascript':
                return <JavascriptIcon color="action" />;
              case 'python':
                return <PythonIcon color="action" />;
              default:
                break;
            }
          };

          return (
            <TableRow
              hover
              onClick={event => this.fsm.selectPreset(event, index)}
              selected={isChecked}
              key={index}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isChecked} />
              </TableCell>
              <TableCell padding="none">{preset.icon}</TableCell>
              <TableCell>{preset.description}</TableCell>
              <TableCell>{languageSwitch()}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  getStepper = (step, index) => (
    <Step key={index}>
      <StepLabel
        error={step.error}
        optional={
          <Typography variant="caption" color="error">
            {step.optional}
          </Typography>
        }
      >
        {step.label}
      </StepLabel>
      <StepContent>{step.content}</StepContent>
    </Step>
  );

  render() {
    const { classes, item, installation } = this.props;

    const title = item.source
      ? 'Linked software asset'
      : 'Configure source code location';

    const isManaged = installation.isSoftwareItemManaged(item.id);

    const steps = [
      {
        label: 'Select repository',
        content: (
          <React.Fragment>
            <Typography>
              Which repository is the source of the software item?
            </Typography>
            {this.reposTable()}
            <div className={classes.actionsContainer}>
              <Button
                className={classes.button}
                onClick={this.props.cancelHandler}
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.repoSelected === undefined}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.fsm.next}
              >
                Next
              </Button>
            </div>
          </React.Fragment>
        )
      },
      {
        label: 'Select application type',
        content: (
          <React.Fragment>
            <Typography>
              Which application type that describes your application?
            </Typography>
            {this.presetsTable()}
            <div className={classes.actionsContainer}>
              <Button onClick={this.fsm.back} className={classes.button}>
                Back
              </Button>
              <Button
                disabled={this.state.presetSelected === undefined}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.fsm.next}
              >
                Next
              </Button>
            </div>
          </React.Fragment>
        )
      },
      {
        label: 'Select integration branch name',
        content: (
          <React.Fragment>
            <Typography>
              Continue or provide the integration branch name if different than
              master
            </Typography>
            <form>
              <TextField
                label="branch"
                value={this.state.branch}
                onChange={event => this.fsm.branchChange(event.target.value)}
              />
            </form>
            <div className={classes.actionsContainer}>
              <Button onClick={this.fsm.back} className={classes.button}>
                Back
              </Button>
              <Button
                disabled={this.state.presetSelected === undefined}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.fsm.next}
              >
                Next
              </Button>
            </div>
          </React.Fragment>
        )
      },
      {
        label: 'Select path',
        content: (
          <React.Fragment>
            <Typography>
              Continue or provide the software item root path if different than
              the repository root
            </Typography>
            <form>
              <TextField
                label="path"
                value={this.state.path}
                onChange={event => this.fsm.pathChange(event.target.value)}
              />
            </form>
            <div className={classes.actionsContainer}>
              <Button onClick={this.fsm.back} className={classes.button}>
                Back
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.fsm.configure}
              >
                Configure
              </Button>
            </div>
          </React.Fragment>
        )
      },
      {
        label: 'Merge pull request',
        content: (
          <React.Fragment>
            <Typography>{this.state.stepDescription}</Typography>
            <div className={classes.actionsContainer}>
              <Button disabled className={classes.button}>
                Back
              </Button>
              <Button
                disabled={!this.state.pullRequestHref}
                className={classes.button}
                variant="contained"
                color="primary"
                href={this.state.pullRequestHref}
                target="compliancepal"
              >
                View
              </Button>
            </div>
          </React.Fragment>
        )
      },
      {
        label: 'Configuration result',
        error: this.state.cancelled,
        optional: this.state.cancelled && 'User cancelled the pull request',
        content: this.state.cancelled ? (
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.fsm.restart}
            >
              Restart
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={this.props.cancelHandler}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.fsm.done}
            >
              Done
            </Button>
          </div>
        )
      }
    ];

    return !isManaged ? (
      <Card className={this.props.className}>
        <CardHeader title={title} />
        <CardContent>
          <Stepper orientation="vertical" activeStep={this.state.activeStep}>
            {steps.map(this.getStepper)}
          </Stepper>
        </CardContent>
      </Card>
    ) : null;
  }
}

export default withStyles(styles)(ConfigureSourceCard);
