import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';

export const SelectRepoStep = ({ repositories, next }) => {
  const [selected, setSelected] = useState(null);
  const [requested, setRequested] = useState(false);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography>
        The architecture description resides in the following repository:
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>Repository</TableCell>
            <TableCell>Private</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {repositories.map((repo, index) => {
            return (
              <TableRow
                hover
                key={repo.full_name}
                onClick={() => {
                  setSelected(selected === index ? false : index);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={selected === index}></Checkbox>
                </TableCell>
                <TableCell>{repo.full_name}</TableCell>
                <TableCell>{repo.private ? 'yes' : 'no'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div>
        <Button
          color="primary"
          disabled={typeof selected !== 'number' || requested}
          style={{
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
          }}
          variant="contained"
          onClick={async () => {
            try {
              setRequested(true);
              setSelected(null);
              await next(selected);
            } finally {
              setRequested(false);
            }
          }}
        >
          Configure
        </Button>
      </div>
    </React.Fragment>
  );
};
