import React, { useState, useEffect } from 'react';
import {
  IconButton,
  CircularProgress,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';

const ActionButton = ({ action, path, title }) => {
  const theme = useTheme();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    return () => {
      setDisabled(false);
    };
  }, []);

  return (
    <Tooltip title={title}>
      <IconButton
        edge="end"
        color="primary"
        disabled={disabled}
        onClick={() => {
          setDisabled(true);

          action().finally(() => {
            disabled && setDisabled(false);
          });
        }}
      >
        <Icon
          path={path}
          size={1}
          color={
            disabled
              ? theme.palette.action.disabled
              : theme.palette.primary.main
          }
        />
        {disabled && (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -20,
              marginLeft: -20,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ActionButton;
