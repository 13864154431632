import React from 'react';
import { withStyles } from '@material-ui/core';
import Backend from './backend';

import './styles.css';

const styles = theme => ({
  layout: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
    //padding: theme.spacing(),
    border: '1px solid lightgray'
  }
});

class Viewer extends React.Component {
  svg = React.createRef();
  backend = new Backend({
    borderWidth: 1,
    padding: 8,
    teamId: this.props.teamId
  });

  resize = () => {
    this.backend.update(this.props);
  };

  componentDidUpdate() {
    this.backend.removeOutput && this.backend.removeOutput();
    this.backend.update(this.props);
  }

  componentDidMount() {
    this.backend.setHostElement(this.svg.current);
    this.backend.update(this.props);
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <svg ref={this.svg} className="architecture-viewer" height="350" />
      </div>
    );
  }
}

export default withStyles(styles)(Viewer);
