import React from 'react';
import { Router } from '@reach/router';

import ProtectedRoute from './components/ProtectedRoute';

import About from './pages/About';
import Architecture from './pages/Architecture';
// import ArchitectureEditor from './pages/ArchitectureEditor';
import Landing from './pages/Landing';
import SoftwareItem from './pages/SoftwareItem';
import SoftwareItems from './pages/SoftwareItems';
import Team from './pages/Team';
import TeamSetup from './pages/TeamSetup';
import TechnicalReport from './pages/TechnicalReport';
import TechnicalReports from './pages/TechnicalReports';
import ArchitectureSetup from './pages/ArchitectureSetup';
import SoftwareItemSetup from './pages/SoftwareItemSetup';
import { InstallationProvider } from './stores/InstallationProvider';
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { UiProvider } from './stores/UiProvider';
import SnackbarProvider from './stores/SnackbarProvider';
import SoftwareItemPull from './pages/SoftwareItemPull';
import TeamOverview from './pages/TeamOverview';
import TeamProcesses from './pages/TeamProcesses';
import { ProfileProvider } from './stores/profileProvider';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

// const store = ConsoleStore.create({});

export const theme = createMuiTheme({
  palette: {
    primary: blue
  },
  typography: {
    // useNextVariants: true
  }
});

const NotFound = () => <div>Not Found</div>;

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <ProfileProvider>
        <InstallationProvider>
          <UiProvider>
            <SnackbarProvider>
              <Router>
                {
                  //transitioned
                }
                <ProtectedRoute component={Landing} path="/" />
                <ProtectedRoute component={TeamSetup} path="/setup/team" />
                <ProtectedRoute
                  component={TeamOverview}
                  path="/t/:installationId/overview"
                />
                <ProtectedRoute
                  component={Team}
                  path="/t/:installationId/settings"
                />
                <ProtectedRoute
                  component={TeamProcesses}
                  path="/t/:installationId/processes"
                />
                <ProtectedRoute
                  component={Architecture}
                  path="/t/:installationId/architecture"
                />
                <ProtectedRoute
                  component={TechnicalReports}
                  path="/t/:installationId/technical-files"
                />
                <ProtectedRoute
                  component={TechnicalReport}
                  path="/t/:installationId/technical-files/:id"
                />
                <ProtectedRoute
                  component={SoftwareItems}
                  path="/t/:installation_id/software-items"
                />
                <ProtectedRoute
                  component={SoftwareItem}
                  path="/t/:installationId/software-items/:softwareItemId"
                />
                <ProtectedRoute
                  component={SoftwareItemPull}
                  path="/t/:installationId/software-items/:softwareItemId/pulls/:pullId"
                />

                <ProtectedRoute
                  component={ArchitectureSetup}
                  path="/t/:installation_id/setup/architecture"
                />
                <ProtectedRoute
                  component={SoftwareItemSetup}
                  path="/t/:installationId/setup/software-item/:softwareItemId"
                />
                {
                  // @ts-ignore
                  <NotFound default />
                }
                {
                  //legacy
                }
                <ProtectedRoute component={About} path="/about" />

                {/*
                <ProtectedRoute
                  component={SoftwareItemChecks}
                  path="/t/:installationId/software/items/:id/checks"
                />
                <ProtectedRoute
                  component={SoftwareItemSoupCheck}
                  path="/t/:installation/software/items/:id/checks/:sha"
                />
                */}
              </Router>
            </SnackbarProvider>
          </UiProvider>
        </InstallationProvider>
      </ProfileProvider>
    </MuiThemeProvider>
  );
};

export default App;
