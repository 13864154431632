import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Link } from '@reach/router';

import CompliancePalAvatar from '../CompliancePalAvatar';

const styles = (theme) => ({
  name: {
    flexGrow: 1,
    fontSize: '24px',
    display: 'inline-block',
  },
  compliance: {
    color: '#376da5',
    fontFamily: 'Helvetica',
    fontWeight: 'bolder',
  },
  pal: {
    color: '#FFBC00',
    fontFamily: 'Helvetica',
    fontWeight: 'bolder',
  },
  home: {
    marginLeft: theme.spacing(1),
    textDecoration: 'none',
  },
});

const Brand = ({ classes }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CompliancePalAvatar />
      <Link to="/" className={classes.home}>
        <Typography variant="h6" color="inherit" className={classes.name}>
          <span className={classes.compliance}>Compliance</span>
          <span className={classes.pal}>Pal</span>
        </Typography>
      </Link>
    </div>
  );
};

Brand.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Brand);
