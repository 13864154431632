import React, { useEffect, useState } from 'react';
import Page from '../../components/Page2';
import SoftwareItemConfiguration from './SoftwareItemConfiguration';
import { navigate } from '@reach/router';
import { teamSoftwareItemUrl } from '../../utils/urls';
import { firestore } from '../../stores/firebaseInit';
import { useInstallation } from '../../stores/InstallationProvider';
import { Container } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';

const SoftwareItemSetup = ({ installationId, softwareItemId }) => {
  const [item, setItem] = useState();

  const [installation] = useInstallation();

  useEffect(() => {
    firestore
      .collection('installations')
      .doc(installationId)
      .collection('software-items')
      .doc(encodeURIComponent(softwareItemId))
      .get()
      .then(doc => {
        const data = doc.data();

        console.log(data);
        setItem(data);
      });
  }, [softwareItemId, installationId]);

  return (
    <Page team={installationId}>
      <PageHeader
        title={`Software items - ${softwareItemId} - configure`}
      ></PageHeader>
      {item && installation.data ? (
        <Container>
          <SoftwareItemConfiguration
            installation={installation.data}
            installation_id={installation.id}
            configuration={installation.data.getElementConfiguration(item.id)}
            repositories={installation.data.repositories || []}
            cancelHandler={() => {
              navigate(teamSoftwareItemUrl(installationId, softwareItemId));
            }}
            item={item}
          />
        </Container>
      ) : null}
    </Page>
  );
};

export default SoftwareItemSetup;
