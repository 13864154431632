import { default as firebase } from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';

import { result as config } from './config.json';

try {
  firebase.initializeApp(config);
} catch (error) {
  console.log(error);
}

const auth = firebase.auth();
const analytics = firebase.analytics();
const firestore = firebase.firestore();
const functions = firebase.functions();
const perf = firebase.performance();

firestore.settings({});

export { auth, analytics, firebase, firestore, functions, perf };
