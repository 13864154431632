import React from 'react';
import clsx from 'clsx';

import { withStyles, Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

const styles = theme => ({
  red: {
    backgroundColor: red['200']
  },
  green: {
    backgroundColor: green['200']
  },
  yellow: {
    backgroundColor: yellow['200']
  }
});

class SafetyClass extends React.Component {
  render() {
    const { classes, cls, compact } = this.props;
    let fill;

    switch (cls) {
      case 'A':
        fill = 'green';
        break;
      case 'B':
        fill = 'yellow';
        break;
      case 'C':
        fill = 'red';
        break;
      default:
    }

    return (
      <Tooltip title="Safety classification">
        <Chip
          label={compact ? cls : `Class ${cls}`}
          className={clsx(classes[fill], this.props.className)}
        />
      </Tooltip>
    );
  }
}

export default withStyles(styles)(SafetyClass);
