import React from 'react';
import Page from '../components/Page2';
import { Container, Typography } from '@material-ui/core';

export default () => (
  <Page>
    <Container>
      <Typography>Build information</Typography>
      <Typography>Ref: {process.env.REACT_APP_GITHUB_REF}</Typography>
      <Typography>Sha: {process.env.REACT_APP_GITHUB_SHA}</Typography>
    </Container>
  </Page>
);
