import React from 'react';
import { renderToString } from 'react-dom/server';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import remark from 'remark';
import remark2react from 'remark-react';

const useStyles = makeStyles(theme => ({
  name: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    lineHeight: '1.1',
    textAlign: 'center'
  },
  type: {
    fontFamily: '"Courier New", Courier, monospace',
    textAlign: 'center'
  }
}));

const Content = React.forwardRef((props, ref) => {
  const classes = useStyles({});
  return (
    <div {...props} ref={ref}>
      <div className={classes.name}>
        {props.name.split(' ').map((frag, index) => (
          <div key={index}>{frag}</div>
        ))}
      </div>
      <div className={classes.type}>«item»</div>
    </div>
  );
});

export const TooltipLabel = ({ title, name, open }) => {
  const trim = 150;

  return (
    <Tooltip
      title={
        remark()
          .use(remark2react, {
            remarkReactComponents: {
              h1: ({ children }) => (
                <div style={{ fontWeight: 'bolder' }}>{children}</div>
              ),
              p: ({ children }) => <div>{children}</div>
            }
          })
          .processSync(
            title.length <= trim ? title : title.substring(0, trim) + '...'
          ).contents
      }
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <Content name={name} />
    </Tooltip>
  );
};

export const renderLabel = props =>
  renderToString(
    <div id={`element-${props.id}`}>
      <TooltipLabel {...props} />
    </div>
  );
