import React from 'react';
import Page from '../components/Page2';
import PageHeader from '../components/PageHeader';
import {
  Grid,
  Typography,
  useTheme,
  Container,
  Paper,
  Avatar
} from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiChevronRight,
  mdiStateMachine,
  mdiFileCadBox,
  mdiCodeBracesBox
} from '@mdi/js';
import {
  teamArchitectureUrl,
  teamProcessesUrl,
  teamSoftwareItemsUrl
} from '../utils/urls';
import { Link } from '@reach/router';
import { useInstallation } from '../stores/InstallationProvider';

const Arrow = () => {
  const theme = useTheme();

  return (
    <Grid item>
      <Icon size={2} path={mdiChevronRight} color={theme.palette.grey[500]} />
    </Grid>
  );
};

const Step = ({ content, disabled = false, href = null, path }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={2}
      style={{
        height: theme.spacing(12)
      }}
    >
      <Paper
        style={{
          overflow: 'visible',
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {path && (
          <Avatar
            style={{
              border: '1px solid lightgrey',
              backgroundColor: 'white',
              top: -20,
              right: -20,
              position: 'absolute'
            }}
          >
            <Icon size={0.75} path={path} color={theme.palette.grey[600]} />
          </Avatar>
        )}
        {content.map(line => (
          <Typography
            component={href ? Link : Typography}
            to={href}
            key={line}
            align="center"
            color={disabled ? 'textSecondary' : 'textPrimary'}
          >
            {line}
          </Typography>
        ))}
      </Paper>
    </Grid>
  );
};

export default ({ installationId }) => {
  const theme = useTheme();

  const [installation, actions] = useInstallation();
  actions.init(installationId);

  console.log(installation);

  return (
    <Page team={installationId} title="Overview - CompliancePal console">
      <PageHeader title="Overview" />
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          spacing={2}
          style={{
            paddingTop: theme.spacing(8)
          }}
        >
          <Step
            content={['Development', 'planning']}
            path={mdiStateMachine}
            href={teamProcessesUrl(installationId)}
          />
          <Arrow />
          <Step content={['Requirements', 'analysis']} disabled />
          <Arrow />
          <Step
            content={['Architectural', 'design']}
            path={mdiFileCadBox}
            href={teamArchitectureUrl(installationId)}
          />
          <Arrow />
          <Step
            content={['Detailed', 'design']}
            path={mdiCodeBracesBox}
            href={teamSoftwareItemsUrl(installationId)}
          />
          <Arrow />
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          spacing={2}
          style={{
            paddingTop: theme.spacing(8)
          }}
        >
          <Arrow />
          <Step
            content={['Unit implementation', 'and verification']}
            disabled
          />
          <Arrow />
          <Step content={['Integration', 'and testing']} disabled />
          <Arrow />
          <Step content={['System testing']} disabled />
          <Arrow />
          <Step content={['Release']} disabled />
        </Grid>
      </Container>
    </Page>
  );
};
