import React from 'react';
import {
  Typography,
  Divider,
  Tabs,
  Tab,
  useTheme,
  withStyles
} from '@material-ui/core';
import { navigate } from '@reach/router';

const AntTabs = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    marginBottom: theme.spacing(2)
  },
  indicator: {
    backgroundColor: '#1890ff'
  }
}))(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1
    },
    '&$selected': {
      // color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      // color: '#40a9ff'
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

export default ({ title, tabs, value = 0, changeHandler = null }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography variant="h5">{title}</Typography>
      {tabs ? (
        <AntTabs
          value={value}
          onChange={(event, newValue) => {
            changeHandler
              ? changeHandler(newValue)
              : navigate(`?tab=${newValue}`);
          }}
        >
          {tabs.map(tab => (
            <AntTab
              key={tab}
              label={tab}
              component={Typography}
              variant="h6"
            ></AntTab>
          ))}
        </AntTabs>
      ) : (
        <Divider
          style={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2)
          }}
        />
      )}
    </React.Fragment>
  );
};
