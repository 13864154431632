import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiCheck,
  mdiCommentProcessingOutline,
  mdiShieldCheck,
  mdiShapeOutline,
} from '@mdi/js';

import PackageInfo from '../SoftwareItemSoupCheck/PackageInfo';
import DecisionRecordDescription from '../SoftwareItemSoupCheck/DecisionRecordDescription';
import { green } from '@material-ui/core/colors';
import ActionButton from './ActionButton';
import { RiskAnalysis } from './RiskAnalysis';

export const withNameSpace = (name, namespace) => `${namespace}:${name}`;

export function AddedDependency({
  action,
  disabled = false,
  handledDeps,
  name,
  namespace,
  pullUrl,
  registryUrl,
  spec,
}) {
  const theme = useTheme();

  const [safetyClass, setSafetyClass] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  return (
    <Card
      id={withNameSpace(name, namespace)}
      style={{
        marginBottom: theme.spacing(1),
      }}
    >
      <CardHeader
        title={
          <Grid direction="row" container alignItems="center">
            <Typography
              variant="h5"
              style={{
                marginRight: theme.spacing(1),
              }}
            >
              {name}
            </Typography>
            {handledDeps && handledDeps[withNameSpace(name, namespace)] && (
              <Icon
                title="Risk analysis performed successfuly"
                size={1}
                path={mdiShieldCheck}
                style={{
                  fill: green[500],
                }}
              />
            )}
          </Grid>
        }
        titleTypographyProps={{
          variant: 'h5',
        }}
        style={{
          color: green[500],
        }}
      ></CardHeader>
      <CardContent>
        <PackageInfo dep={name} spec={spec} registryUrl={registryUrl} />
        <DecisionRecordDescription
          title="Decision record"
          description={spec.compliance.items[name].description}
          safetyClass={safetyClass}
        />
        <RiskAnalysis
          open={open}
          closeHandler={() => setOpen(false)}
          assignAction={(cls) => {
            setOpen(false);
            setSafetyClass(cls);
          }}
        ></RiskAnalysis>
      </CardContent>
      {disabled ||
      (handledDeps && handledDeps[withNameSpace(name, namespace)]) ? null : (
        <CardActions disableSpacing>
          <Tooltip title="Perform safety classification">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <Icon path={mdiShapeOutline} size={1}></Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Discuss on pull request">
            <IconButton edge="end" href={pullUrl} target="github">
              <Icon path={mdiCommentProcessingOutline} size={1} />
            </IconButton>
          </Tooltip>
          <div
            style={{
              flexGrow: 1,
            }}
          ></div>
          {safetyClass && (
            <ActionButton
              action={() =>
                action({
                  adrDescription: spec.compliance.items[name].description,
                  softwareItemDescription: spec.soups[name].description,
                  softwareItemName: name,
                  safetyClass,
                })
              }
              path={mdiCheck}
              title="Accept usage rationale"
            />
          )}
          <div
            style={{
              width: '12px',
            }}
          ></div>
        </CardActions>
      )}
    </Card>
  );
}
