import React from 'react';
import { withStyles } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PartitioningViewer from '../viewers/Partitioning';
import SafetyClassChip from '../chips/SafetyClass';

import { classLens, descriptionLens } from '../../utils/lenses/software-items';
import { SoftwareItemLink } from '../../pages/SoftwareItem/DecisionRecordsCard';

const getItemName = (item) => {
  if (item.location === 'internal') return item.id;

  const namespace = item.technology.toLowerCase();

  return item.id.startsWith(namespace) ? item.id : `${namespace}:${item.id}`;
};

/**
 *
 * @param {import('@material-ui/core').Theme} theme
 */
const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

/**
 *
 * @param {object} props
 * @param {object} props.classes
 * @param {array} props.items
 * @param {string} props.teamId
 * @param {string} props.variant
 */
const SoftwareItemsCard = ({ classes, items, teamId, variant }) => {
  const elements = items.filter(
    (element) => !(element.location === 'external' || element.type === 'person')
  );

  return (
    <Card className={classes.card}>
      <CardContent>
        {variant === 'list' ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="none">Class</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Managed</TableCell>
                <TableCell>SOUP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items
                .filter((item) => classLens.get(item))
                .map((item) => {
                  const isManaged = item.isManaged() ? 'yes' : '';

                  console.log(getItemName(item));

                  return (
                    <TableRow key={item.id}>
                      <TableCell padding="none">
                        {classLens.get(item) && (
                          <SafetyClassChip cls={classLens.get(item)} compact />
                        )}
                      </TableCell>
                      <TableCell>
                        <SoftwareItemLink
                          installationId={teamId}
                          softwareItemId={getItemName(item)}
                        />
                      </TableCell>
                      <TableCell>{descriptionLens.get(item)}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{isManaged}</TableCell>
                      <TableCell>{item.isSoup ? 'yes' : null}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        ) : (
          <PartitioningViewer elements={elements} zoom teamId={teamId} />
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SoftwareItemsCard);
