import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import { green, red, orange } from '@material-ui/core/colors';

import packageShape from './shapes/package';
import registryShape from './shapes/registry';
import boundaryShape from './shapes/boundary';

const stdlib = language => {
  switch (language) {
    case 'python':
      return 'stdlib';
    default:
      return 'node.js';
  }
};

const registry = language => {
  switch (language) {
    case 'python':
      return 'pypi';
    default:
      return 'npm registry';
  }
};

const hasBuiltins = spec =>
  Object.keys(spec).some(
    nodeName => spec[nodeName].builtin || spec[nodeName].stdlib
  );

const hasExternals = spec =>
  Object.keys(spec).some(nodeName => spec[nodeName].relative === false);

export default class D3Backend {
  constructor({ element }) {
    this.element = element;
  }

  removeOutput() {
    const output = d3.select('.output');

    output && output.remove();
  }

  update = ({
    boundaries,
    changes,
    diff,
    handled,
    manifest,
    name,
    language = 'javascript',
    spec,
    zoom,
    onClickPackage
  }) => {
    this.removeOutput();

    const g = new dagreD3.graphlib.Graph({
      compound: true
      // multigraph: true
    }).setGraph({
      rankdir: 'TD'
      // rankdir: this.props.compact ? 'TD' : 'LR',
      // ranker: 'longest-path'
    });

    const dependencies = {};

    if (hasBuiltins(spec)) {
      dependencies[stdlib(language)] = [];
    }

    if (hasExternals(spec)) {
      dependencies[registry(language)] = [];
    }

    if (diff && changes) {
      // add to spec data the removed packages so that they can be rendered
      changes.removed.forEach(change => {
        console.log();
        spec[change.name] = {
          public: true,
          relative: false,
          soup: true
        };
      });
    }

    Object.keys(spec).forEach(nodeName => {
      const node = spec[nodeName];
      let fill;

      if (node.relative === false) {
        if (diff) {
          if (changes) {
            if (
              changes.added
                .map(change =>
                  typeof change === 'string' ? change : change.name
                )
                .includes(nodeName)
            )
              fill = green[100];
            if (changes.modified.map(change => change.name).includes(nodeName))
              fill = orange[100];
            if (changes.removed.map(change => change.name).includes(nodeName))
              fill = red[100];

            fill &&
              dependencies[
                node.builtin || node.stdlib
                  ? stdlib(language)
                  : registry(language)
              ].push({
                interactive: true,
                label: nodeName,
                labelType: 'html',
                shape: 'package',
                style: `fill: ${fill};`,
                onClick: () => {
                  onClickPackage(nodeName);
                }
              });
          }
        } else {
          dependencies[
            node.builtin || node.stdlib ? stdlib(language) : registry(language)
          ].push({
            interactive: false,
            label: nodeName,
            labelType: 'html',
            shape: 'package',
            style: `fill: white;`,
            _class: handled.includes(nodeName) ? 'known' : 'unknown'
          });
        }
      }
    });

    if (boundaries) {
      g.setNode('module', {
        label: manifest.name || name,
        shape: 'boundary',
        boundaries
      });
    } else {
      g.setNode('module', {
        label: manifest.name || name,
        shape: 'package'
      });
    }

    const regs = Object.keys(dependencies).length;

    Object.keys(dependencies).forEach(key => {
      if (dependencies[key].length !== 0) {
        g.setNode(key, {
          label: '',
          source: key,
          shape: 'registry',
          dependencies: dependencies[key],
          maxWidth: regs === 2 ? 70 : 130
        });

        g.setEdge('module', key, {
          label: '',
          arrowhead: 'vee',
          curve: d3.curveBasis
        });
      }
    });

    const svg = d3.select(this.element.current);

    const render = new dagreD3.render();

    render.shapes().boundary = boundaryShape;
    render.shapes().package = packageShape;
    render.shapes().registry = registryShape;

    // @ts-ignore
    render(svg, g);

    const output = svg.select('.output');

    const zoomHandler = d3.zoom().on('zoom', () => {
      output.attr('transform', d3.event.transform);
    });

    if (zoom) {
      svg.call(zoomHandler);
    }

    const {
      height: outputHeight,
      width: outputWidth
    } = output.node().getBoundingClientRect();
    // const svgRatio = g.graph().height / g.graph().width;

    const {
      width: viewWidth
    } = this.element.current.parentNode.getBoundingClientRect();
    const scale = Math.min(viewWidth / outputWidth, 1);

    const viewHeight = (outputHeight + 20) * scale;

    svg.attr('width', viewWidth);
    svg.attr('height', zoom ? (viewWidth * 9) / 16 : viewHeight);

    svg.call(
      zoomHandler.transform,
      d3.zoomIdentity
        .translate(
          (viewWidth - outputWidth * scale) / 2,
          (viewHeight - (outputHeight - 20) * scale) / 2
        )
        .scale(scale)
    );
  };
}
