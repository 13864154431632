import React from 'react';
import { Avatar, withStyles } from '@material-ui/core';

import logo from './logo.svg';

const styles = theme => ({
  logo: {
    height: '30px',
    width: '30px'
  },
});

const CompliancePalAvatar = ({ classes }) => (<Avatar src={logo} className={classes.logo}></Avatar>);

export default withStyles(styles)(CompliancePalAvatar);
