import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { firestore, functions } from './firebaseInit';
import { useProfile } from './profileProvider';

/**
 * @callback initCallback
 * @param {string} id - Installation Id
 * @returns {void}
 */

/**
 *
 * @typedef {object} SoftwareItemDraftCallbackOptions
 * @property {string} adrDescription
 * @property {string} commit
 * @property {string} installationId
 * @property {string} parentId
 * @property {string} pullNumber
 * @property {string} ref
 * @property {string} softwareItemDescription
 * @property {string} softwareItemName
 */

/**
 *
 * @callback addSoftwareItemDraftCallback
 * @param {object} SoftwareItemDraftCallbackOptions
 */

/**
 *
 * @typedef {object} SoftwareItemCallbackOptions
 * @property {string} commit
 * @property {string} context
 * @property {string} description
 * @property {string} id
 * @property {string} ref
 * @property {string} softwareItemName
 */

/**
 *
 * @callback addSoftwareItemCallback
 * @param {object} SoftwareItemCallbackOptions
 */

/**
 *
 * @callback supersedAdrCallback
 * @param {{ adrId: string, commit: string, description: string, installationId: string, pullNumber: string, ref: string, safetyClass: string}} options
 * @returns {Promise}
 */

/**
 *
 * @callback deprecateAdrCallback
 * @param {{adrId: string, commit: string, installationId: string, pullNumber: string, softwareItemId: string}} options
 * @returns {Promise}
 */

/**
 * @typedef Actions
 * @property {initCallback} init - Initialize installation provider
 * @property {addSoftwareItemDraftCallback} addSoftwareItemDraft - Add new software item
 * @property {addSoftwareItemCallback} addSoftwareItem - Add new software item
 * @property {supersedAdrCallback} supersedAdr
 * @property {deprecateAdrCallback} deprecateAdr
 */

/**
 *
 * @typedef State
 * @property {string} id
 * @property {object} data
 * @property {array} pulls
 */

class Installation {
  elements = {};
  repositories = [];
  team = {
    members: []
  };

  constructor(props = {}) {
    for (var key in props) {
      this[key] = props[key];
    }
  }

  getElementConfiguration = id => {
    return this.elements[id] && this.elements[id].configuration;
  };

  isSoftwareItemManaged = id => {
    const configuration = this.getElementConfiguration(id);

    return configuration ? configuration.state === 'completed' : false;
  };
}

/**
 * @name reducer
 * @param {State} state
 * @param {*} action
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'get':
      return action.id === state.id
        ? state
        : { ...state, id: action.id, data: null, pulls: [] };

    case 'update':
      const { data } = action;

      console.log(data);
      return {
        ...state,
        data: new Installation(action.data)
      };
    case 'pulls':
      return {
        ...state,
        pulls: action.pulls
      };
    default:
      return state;
  }
};

const getOpenPulls = (id, dispatch) => {
  firestore
    .collection('installations')
    .doc(id.toString())
    .collection('software-items')
    .get()
    .then(async softwareItemsSnap => {
      const r = await Promise.all(
        softwareItemsSnap.docs.map(softwareItem => {
          return softwareItem.ref
            .collection('pulls')
            .where('state', '==', 'open')
            .get()
            .then(pullsSnap => {
              const pulls = pullsSnap.docs.map(pull => {
                const data = pull.data();

                return {
                  id: pull.id,
                  title: data.title,
                  softwareItem: softwareItem.id
                };
              });

              return pulls.length > 0 ? pulls : null;
            });
        })
      );

      return r
        .filter(a => a !== null)
        .reduce((result, a) => result.concat(a), []);
    })
    .then(pulls => {
      dispatch({
        type: 'pulls',
        pulls
      });
    });
};

export const InstallationContext = createContext(null);

export const InstallationProvider = ({ children }) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    id: null
  });

  const [profile] = useProfile();

  useEffect(() => {
    const disposables = [];

    if (profile && state.id !== null) {
      disposables.push(
        firestore
          .collection('installations')
          .doc(state.id)
          .onSnapshot(
            doc => {
              dispatch({
                type: 'update',
                data: doc.data()
              });

              getOpenPulls(state.id, dispatch);
            },
            error => {
              // FIXME: unsubscribe on logout
              console.log(error);

              disposables.forEach(disposable => disposable());
            }
          )
      );

      console.log(disposables.length);
    }

    return () => {
      disposables.forEach(disposable => disposable());
    };
  }, [profile, state.id]);

  /**
   * @type Actions
   */
  const actions = {
    init: id => {
      if (id) {
        dispatch({
          type: 'get',
          id
        });
      }
    },
    addSoftwareItemDraft: functions.httpsCallable(
      'httpsOnCallSoftwareItemsNewDraft'
    ),
    addSoftwareItem: functions.httpsCallable('httpsOnCallSoftwareItemsNew'),
    supersedAdr: opts => {
      console.log(opts);
      return functions.httpsCallable('httpsOnCallAdrsSupersed')(opts);
    },
    deprecateAdr: functions.httpsCallable('httpsOnCallAdrsDeprecate')
  };

  return (
    <InstallationContext.Provider value={[state, actions]}>
      {children}
    </InstallationContext.Provider>
  );
};

/**
 * @name useInstallation
 * @returns {[State, Actions]}
 */
export const useInstallation = () => useContext(InstallationContext);
