import React, { createContext, useState, useContext } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from 'mdi-material-ui/Close';

const SnackbarContext = createContext(null);

const SnackbarProvider = ({ children }) => {
  const [state, setState] = useState({
    open: false,
    message: null
  });

  const onClose = () => setState({ ...state, open: false });

  return (
    <React.Fragment>
      <SnackbarContext.Provider
        value={{
          showMessage: message =>
            setState(state => ({
              ...state,
              open: true,
              message
            }))
        }}
      >
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={state.open}
        message={state.message}
        autoHideDuration={3000}
        onClose={onClose}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      ></Snackbar>
    </React.Fragment>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);

export default SnackbarProvider;
