import React from 'react';

import { withStyles } from '@material-ui/core';

import Backend from './grid-backend';

import './styles.css';

const styles = () => ({
  layout: {
    border: '1px solid whitesmoke',
    width: '100%',
    fontFamily: 'Arial'
  }
});

class PackageViewer extends React.Component {
  svg = React.createRef();
  backend = new Backend({
    element: this.svg
  });

  resize = () => {
    this.backend.update(this.props);
  };

  componentDidMount() {
    console.log(this.props);
    // @ts-ignore
    this.backend.update(this.props);

    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.layout}>
        <svg ref={this.svg} className="package-viewer">
          <defs>
            <pattern
              id="dots-1"
              patternUnits="userSpaceOnUse"
              width="10"
              height="10"
            >
              <image
                xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScgLz4KICA8cmVjdCB4PScwJyB5PScwJyB3aWR0aD0nMScgaGVpZ2h0PScxJyBmaWxsPSdibGFjaycgLz4KPC9zdmc+"
                x="0"
                y="0"
                width="10"
                height="10"
              ></image>
            </pattern>
            <pattern
              id="smalldot"
              patternUnits="userSpaceOnUse"
              width="5"
              height="5"
            >
              <image
                xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc1JyBoZWlnaHQ9JzUnPgo8cmVjdCB3aWR0aD0nNScgaGVpZ2h0PSc1JyBmaWxsPScjZmZmJy8+CjxyZWN0IHdpZHRoPScxJyBoZWlnaHQ9JzEnIGZpbGw9JyNjY2MnLz4KPC9zdmc+"
                x="0"
                y="0"
                width="5"
                height="5"
              ></image>
            </pattern>
            <pattern
              id="lightstripe"
              patternUnits="userSpaceOnUse"
              width="5"
              height="5"
            >
              <image
                xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc1JyBoZWlnaHQ9JzUnPgogIDxyZWN0IHdpZHRoPSc1JyBoZWlnaHQ9JzUnIGZpbGw9J3doaXRlJy8+CiAgPHBhdGggZD0nTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVonIHN0cm9rZT0nIzg4OCcgc3Ryb2tlLXdpZHRoPScxJy8+Cjwvc3ZnPg=="
                x="0"
                y="0"
                width="5"
                height="5"
              ></image>
            </pattern>
            <pattern
              id="diagonal-stripe-1"
              patternUnits="userSpaceOnUse"
              width="10"
              height="10"
            >
              <image
                xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg=="
                x="0"
                y="0"
                width="10"
                height="10"
              ></image>
            </pattern>
          </defs>
        </svg>
      </div>
    );
  }
}

export default withStyles(styles)(PackageViewer);
