import React, { useEffect, useState } from 'react';
import Page from '../components/Page2';
import TagIcon from 'mdi-material-ui/TagOutline';
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  CardActions,
  Button,
  useTheme,
} from '@material-ui/core';
import ArchitectureViewer from './ArchitectureEditor/Viewer';
import { useInstallation } from '../stores/InstallationProvider';
import PageHeader from '../components/PageHeader';
import { Link } from '@reach/router';
import { teamArchitectureSetupUrl } from '../utils/urls';

const Architecture = ({ installationId }) => {
  const [architecture, setArchitecture] = useState();
  const theme = useTheme();

  const [installation, actions] = useInstallation();

  useEffect(() => {
    if (installation.data) {
      setArchitecture(installation.data.architecture.spec);
    }
  }, [installation]);

  actions.init(installationId);

  return (
    <Page team={installationId} title="Architecture - CompliancePal console">
      <PageHeader title="Architecture" tabs={['Description']} />
      <Container>
        {architecture ? (
          <Card
            style={{
              marginTop: theme.spacing(2),
            }}
          >
            <CardContent>
              {architecture.info ? (
                <Grid
                  container
                  style={{
                    marginBottom: theme.spacing(1),
                  }}
                >
                  <div style={{ flexGrow: 1 }} />
                  <TagIcon fontSize="small" />
                  <Typography>v{architecture.info.version}</Typography>
                </Grid>
              ) : null}
              <ArchitectureViewer
                spec={architecture}
                interactive
                teamId={installationId}
              />
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Typography>Architecture repository not configured.</Typography>
            </CardContent>
            <CardActions>
              <Button
                component={Link}
                to={teamArchitectureSetupUrl(installationId)}
                color="primary"
              >
                Configure
              </Button>
            </CardActions>
          </Card>
        )}
      </Container>
    </Page>
  );
};

export default Architecture;
