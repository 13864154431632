import React from 'react';
import {
  Dialog,
  Stepper,
  Step,
  Typography,
  Button,
  StepLabel,
  DialogTitle,
  DialogContent,
  makeStyles,
  useTheme,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const RiskButton = ({ handler, label }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      color="primary"
      variant="contained"
      onClick={handler}
    >
      {label}
    </Button>
  );
};

export const RiskAnalysis = ({
  assignAction,
  closeHandler,
  initialRiskClass = 'C',
  open,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [riskClass, setRiskClass] = React.useState(initialRiskClass);

  const steps = [
    {
      label: 'Hazardous situations assessment',
      info: [
        'A HAZARDOUS SITUATION is a circumstance in which people, property or the environment are exposed to one or more potential sources of HARM. HARM represents physical injury, damage, or both to the health of people or damage to property or the environment.',
        'Can a hazardous situation arise from the failure of the software item?',
      ],
      next: {
        no: {
          step: 3,
          class: 'A',
        },
        yes: {
          step: 1,
        },
      },
    },
    {
      label: 'Risk assessement',
      info: [
        'RISK is the combination of the probability of occurrence of HARM and the severity of that HARM. HARM represents physical injury, damage, or both to the health of people or damage to property or the environment.',
        'Does failure of the software item result in unacceptable risk?',
      ],
      next: {
        no: {
          step: 3,
          class: 'A',
        },
        yes: {
          step: 2,
        },
      },
    },
    {
      label: 'Severity assessment',
      info: 'What severity of injury is possible?',
      next: {
        no: {
          step: 3,
          class: 'B',
          label: 'non-serious',
        },
        yes: {
          step: 3,
          class: 'C',
          label: 'serious/death',
        },
      },
    },
    {
      label: 'Classification result',
      info: `Software item safety classification is ${riskClass}`,
    },
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const restartHandler = () => {
    setCompleted(new Set());
    setActiveStep(0);
    setRiskClass(initialRiskClass);
  };

  const clickHandler = (choice) => () => {
    const nextCompleted = new Set(completed.values());

    nextCompleted.add(activeStep);

    setCompleted(nextCompleted);

    steps[activeStep].next[choice].class &&
      setRiskClass(steps[activeStep].next[choice].class);
    setActiveStep(steps[activeStep].next[choice].step);
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={closeHandler}>
      <DialogTitle>Assigning software safety classification</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={completed.has(index)}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {Array.isArray(steps[activeStep].info) ? (
            steps[activeStep].info.map((line, index) => (
              <Typography className={classes.instructions} key={index}>
                {line}
              </Typography>
            ))
          ) : (
            <Typography className={classes.instructions}>
              {steps[activeStep].info}
            </Typography>
          )}
          <div className={classes.actions}>
            <Button
              className={classes.button}
              disabled={activeStep === 0}
              onClick={restartHandler}
            >
              Restart
            </Button>
            {isLastStep() ? (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => assignAction(riskClass)}
              >
                Assign
              </Button>
            ) : (
              <React.Fragment>
                <RiskButton
                  handler={clickHandler('no')}
                  label={steps[activeStep].next.no.label || 'No'}
                ></RiskButton>
                <RiskButton
                  handler={clickHandler('yes')}
                  label={steps[activeStep].next.yes.label || 'Yes'}
                ></RiskButton>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      </DialogContent>
    </Dialog>
  );
};
