const dagreD3 = require('dagre-d3');

const shape = (parent, bbox, node) => {
  const height = bbox.height;
  const width = bbox.width < 60 ? 60 : bbox.width;
  const factor = 0.6;

  const shape = parent
    .insert('rect', ':first-child')
    .attr('x', -width * factor)
    .attr('y', -height * factor)
    .attr('width', 2 * factor * width)
    .attr('height', 2 * factor * height)
    .attr('style', node.style);

  parent
    .insert('rect')
    .attr('x', -width * factor - 10)
    .attr('y', -15)
    .attr('width', 20)
    .attr('height', 10)
    .attr('style', node.style);

  parent
    .insert('rect')
    .attr('x', -width * factor - 10)
    .attr('y', +5)
    .attr('width', 20)
    .attr('height', 10)
    .attr('style', node.style);

  parent.classed('package', node.interactive);
  parent.classed(node._class, true);

  node.intersect = point => dagreD3.intersect.rect(node, point);

  return shape;
};

module.exports = shape;
