import React from 'react';
import GitIcon from 'mdi-material-ui/Git';
import { Card, CardHeader, IconButton, Tooltip } from '@material-ui/core';
import { Link } from '@reach/router';
import { auth } from '../../stores/firebaseInit';
import { softwareItemSetupUrl } from '../../utils/urls';
import { useInstallation } from '../../stores/InstallationProvider';
export const StartConfigurationCard = ({
  classes,
  installationId,
  softwareItemId
}) => {
  const [installation] = useInstallation();

  return installation.data &&
    installation.data.__abac.owners[auth.currentUser.uid] ? (
    <Card className={classes.card}>
      <CardHeader
        title="Component's source code"
        subheader="Configure a GitHub repository as the source of this software item"
        action={
          <Tooltip title="Start configuration">
            <IconButton
              to={softwareItemSetupUrl(installationId, softwareItemId)}
              component={Link}
            >
              <GitIcon />
            </IconButton>
          </Tooltip>
        }
      />
    </Card>
  ) : null;
};
