import React from 'react';
import {
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  CardActions,
  Tooltip,
  IconButton,
  useTheme,
} from '@material-ui/core';
import { mdiShieldCheck, mdiCheck, mdiCommentProcessingOutline } from '@mdi/js';
import { green, red } from '@material-ui/core/colors';
import { Icon } from '@mdi/react';
import DecisionRecordDescription from '../SoftwareItemSoupCheck/DecisionRecordDescription';
import ActionButton from './ActionButton';
import { withNameSpace } from './AddedDependency';

export const RemovedDependency = ({
  action,
  disabled,
  handledDeps,
  name,
  namespace,
  pullUrl,
  spec,
}) => {
  const theme = useTheme();

  const [safetyClass] = React.useState(
    spec.changes.removed.find((change) => change.name === name).class
  );

  return (
    <Card
      id={withNameSpace(name, namespace)}
      style={{
        marginBottom: theme.spacing(1),
      }}
    >
      <CardHeader
        title={
          <Grid direction="row" container alignItems="center">
            <Typography
              variant="h5"
              style={{
                marginRight: theme.spacing(1),
                color: red[500],
                textDecoration: 'line-through',
              }}
            >
              {name}
            </Typography>
            {handledDeps && handledDeps[withNameSpace(name, namespace)] && (
              <Icon
                title="Risk analysis performed successfuly"
                size={1}
                path={mdiShieldCheck}
                style={{
                  fill: green[500],
                }}
              />
            )}
          </Grid>
        }
        titleTypographyProps={{
          variant: 'h5',
        }}
        style={{
          color: green[500],
        }}
      ></CardHeader>
      <CardContent>
        <DecisionRecordDescription
          title="Decision record"
          description={spec.compliance.items[name].description}
          safetyClass={safetyClass}
        />
      </CardContent>
      {disabled ||
      (handledDeps && handledDeps[withNameSpace(name, namespace)]) ? null : (
        <CardActions disableSpacing>
          <Tooltip title="Discuss on pull request">
            <IconButton href={pullUrl} target="github">
              <Icon path={mdiCommentProcessingOutline} size={1} />
            </IconButton>
          </Tooltip>
          <div style={{ flexGrow: 1 }}></div>
          <ActionButton
            title="Remove"
            path={mdiCheck}
            action={() =>
              action({
                adrId: spec.changes.removed.find(
                  (change) => change.name === name
                ).adrId,
              })
            }
          />
          <div
            style={{
              width: theme.spacing(1.5),
            }}
          ></div>
        </CardActions>
      )}
    </Card>
  );
};
