import dagreD3 from 'dagre-d3';
import packageShape from './package';

const insertRow = (parent, dependencies, index) => {
  const row = parent.insert('g').classed('row', true);

  const g = new dagreD3.graphlib.Graph({}).setGraph({
    rankdir: 'TD'
  });

  const render = new dagreD3.render();

  render.shapes().package = packageShape;

  dependencies.forEach(dep => {
    console.log('inserting dependency', dep);
    g.setNode(dep.label, dep);
  });

  render(row, g);

  const rowHeight = row.node().getBoundingClientRect().height;
  const offset = ((2 * index - 1) * rowHeight) / 2;
  const labelOffset = 15;
  const rowSpacing = 20;
  const middleAlignmentOffset = 30;
  const translate = [
    0,
    offset + rowSpacing * index + middleAlignmentOffset + labelOffset
  ];
  row.attr('transform', 'translate(' + translate + ')');
};

/*
const mapRowsStatic = node => {
  const length = node.dependencies.length;
  const initial = Array.apply(
    null,
    Array(Math.floor(length / node.capacity) + 1)
  ).map(() => []);

  return node.dependencies.reduce((accumulator, dep, index) => {
    const row = Math.floor(index / node.capacity);

    accumulator[row][index % node.capacity] = dep;

    return accumulator;
  }, initial);
};
*/

const mapRowDynamic = node => {
  const result = [];
  result.push([]);

  node.dependencies.forEach(dep => {
    const padding = result[result.length - 1].length * (6 + 4);

    const _width = result[result.length - 1]
      .map(dep => dep.label.length)
      .reduce((sum, length) => sum + length, padding);

    if (_width + dep.label.length > node.maxWidth) {
      console.log('#');
      result.push([]);
    }

    result[result.length - 1].push(dep);
  });

  return result;
};

const shape = (parent, bbox, node) => {
  const { source } = node;
  const container = parent.insert('g').classed('container', true);

  const deps = mapRowDynamic(node);

  deps.forEach((row, index) => insertRow(container, row, index));

  const { width, height } = node.elem.getBoundingClientRect();
  const arrowOffset = 5;

  container.attr(
    'transform',
    'translate(' + [-width / 2, -height / 2 - arrowOffset] + ')'
  );

  container
    .insert('rect', ':first-child')
    .attr('width', width + 40)
    .attr('height', height + 60)
    .attr('transform', 'translate(' + [-20, -30] + ')');

  container
    .insert('rect', ':first-child')
    .attr('width', 30)
    .attr('height', 10)
    .attr('transform', 'translate(' + [-20, -40] + ')');

  container
    .append('text', ':first-child')
    .attr('text-anchor', 'middle')
    .attr('transform', 'translate(' + [width / 2, -10] + ')')
    // .attr('transform', 'translate(' + [0, -10] + ')')
    .text(source);

  node.intersect = point => dagreD3.intersect.rect(node, point);

  return parent;
};

export default shape;
