// Possible alternative implementation using https://github.com/dimerapp/remark-macro

export function parseDiff() {
  const startAdd = '{++';
  const startRemove = '{--';
  const parser = this.Parser;
  const proto = parser.prototype;

  /**
   *
   * @param {function} eat
   * @param {string} value
   * @param {boolean} silent
   */
  proto.inlineTokenizers.diff = (eat, value, silent) => {
    if (silent) return true;

    if (value.charAt(0) !== '{') return;

    let result;

    switch (value.charAt(1)) {
      case '+':
        result = value.match(/\{\+\+(.*)\+\+\}/);
        if (result) {
          console.log(result);
          eat(result[0])({
            type: 'added',
            value: result[1]
          });
        }
        break;
      case '-':
        result = value.match(/\{--(.*)--\}/);
        if (result) {
          return eat(result[0])({
            type: 'removed',
            value: result[1]
          });
        }
        break;
      default:
    }
  };
  proto.inlineTokenizers.diff.locator = (value, fromIndex) => {
    const _startAdd = value.indexOf(startAdd, fromIndex);
    const _startRemove = value.indexOf(startRemove, fromIndex);

    if (_startAdd === -1 || _startRemove === -1) {
      return Math.max(_startAdd, _startRemove);
    }

    if (_startAdd !== -1 && _startRemove !== -1) {
      return Math.min(_startAdd, _startRemove);
    }
  };
  proto.inlineMethods.splice(proto.inlineMethods.indexOf('text'), 0, 'diff');
}

export const hastHandlers = {
  /**
   * @param {function} h
   * @param {{ value: string; }} node
   */
  added: (h, node) => {
    return {
      type: 'element',
      tagName: 'added',
      children: [
        {
          type: 'text',
          value: node.value
        }
      ]
    };
  },
  /**
   * @param {function} h
   * @param {{ value: string; }} node
   */
  removed: (h, node) => {
    return {
      type: 'element',
      tagName: 'removed',
      children: [
        {
          type: 'text',
          value: node.value
        }
      ]
    };
  }
};
