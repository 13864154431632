import React from 'react';
import { Button, Typography, useTheme } from '@material-ui/core';

const ResultCancelledStep = ({ cancel, restart }) => {
  const [disabled, setDisabled] = React.useState(false);
  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography>
        The pull request was closed. You can restart the configuration process
        with a different repository by selecting Restart
      </Typography>
      <div>
        <Button
          disabled={disabled}
          variant="contained"
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
          onClick={async () => {
            setDisabled(true);
            await restart().finally(() => setDisabled(false));
          }}
        >
          Restart
        </Button>
        <Button
          disabled={disabled}
          color="secondary"
          variant="contained"
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
          onClick={async () => {
            setDisabled(true);
            await cancel().finally(() => setDisabled(false));
          }}
        >
          Cancel
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ResultCancelledStep;
