import React, { useEffect, useState } from 'react';

import { Page } from '../components/Page2';
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  Grid,
  CardActions,
  Button,
  Container
} from '@material-ui/core';
import { Link } from '@reach/router';
import { teamOverviewUrl } from '../utils/urls';
import { useProfile } from '../stores/profileProvider';
import Helmet from 'react-helmet';

const styles = () => ({
  card: {}
});

const Team = ({ installation, caption }) => (
  <Card>
    <CardHeader
      title={installation.account}
      avatar={<Avatar src={installation.avatar} />}
    />
    <CardActions>
      <Button
        color="primary"
        to={teamOverviewUrl(installation.id)}
        component={Link}
      >
        {caption}
      </Button>
    </CardActions>
  </Card>
);

const Teams = () => {
  const [installations, setInstallations] = useState([]);

  const [profile] = useProfile();

  console.log(profile);

  useEffect(() => {
    setInstallations(profile ? profile.installations : []);
  }, [profile]);

  return (
    <Page>
      <Helmet>
        <title>CompliancePal console</title>
      </Helmet>
      <Container>
        <Grid container spacing={2}>
          {installations.map(installation => (
            <Grid item key={installation.id} xs={12} sm={6} md={4}>
              <Team installation={installation} caption="View" />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default withStyles(styles)(Teams);
