import React, { useState, useEffect } from 'react';
import Page from '../components/Page2';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link } from '@reach/router';
import { firestore } from '../stores/firebaseInit';
import Status from '../components/Status';
import DecisionRecordDescription from './SoftwareItemSoupCheck/DecisionRecordDescription';
import { teamReportUrl } from '../utils/urls';
import PageHeader from '../components/PageHeader';

export const TechnicalReportCard = ({ adr, installation }) => (
  <Card>
    <CardHeader
      title={adr.title}
      subheader={
        <React.Fragment>
          <Typography display="inline">
            Software item {decodeURIComponent(adr.item)} usage status{' '}
          </Typography>
          <Status value={adr.status}></Status>{' '}
          {adr.superseds && (
            <React.Fragment>
              <Typography display="inline">
                (superseds{' '}
                <Link to={teamReportUrl(installation, adr.superseds)}>
                  {adr.superseds}
                </Link>
                )
              </Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    />
    <CardContent>
      <DecisionRecordDescription
        description={adr.context}
        title="Usage rationale"
        safetyClass={adr.class || 'A'}
      />
      <Divider />
    </CardContent>
  </Card>
);

const TechnicalReport = ({ installationId, id }) => {
  const [adr, setAdr] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const getAdr = async () => {
      const doc = await firestore
        .collection('installations')
        .doc(installationId)
        .collection('adrs')
        .doc(id)
        .get();

      if (doc.exists) {
        setAdr(doc.data());
      }
    };

    getAdr();
  }, [installationId, id]);

  console.log(adr);

  return (
    <Page
      team={installationId}
      title={`Technical file ${id} - CompliancePal console`}
    >
      <PageHeader
        title={`Technical file - ${adr ? adr.title : '...'}`}
        tabs={['Description']}
      />
      {adr && (
        <Container>
          <div
            style={{
              marginTop: theme.spacing(2),
            }}
          />
          <TechnicalReportCard
            adr={adr}
            installation={installationId}
          ></TechnicalReportCard>
        </Container>
      )}
    </Page>
  );
};

export default TechnicalReport;
