export const featuresLens = {
  get: installation =>
    installation.data && installation.data.__features
      ? Object.keys(installation.data.__features)
          .filter(key => installation.data.__features[key] !== true)
          .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      : ['Segregations', 'Interfaces']
};

export const abacLens = {
  get: installation => {
    return installation.data ? installation.data.__abac : {};
  }
};
