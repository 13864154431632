import React, { createContext, useContext } from 'react';

import createPersistedState from 'use-persisted-state';

/**
 *
 * @typedef Actions
 * @property {() => void} reset
 * @property {() => void} closeNavigationDrawer
 * @property {() => void} openNavigationDrawer
 */

/**
 * @typedef State
 * @property {boolean} openNavigationDrawer
 */

export const UiContext = createContext(null);

const usePersistedUiState = createPersistedState(`_cp.ui`);

const defaultUiState = {
  openNavigationDrawer: true
};

export const UiProvider = ({ children }) => {
  const [uiState, setUiState] = usePersistedUiState(defaultUiState);

  const actions = {
    reset: () => {
      console.log('resetting ui state');
      setUiState(() => defaultUiState);
    },
    openNavigationDrawer: () =>
      setUiState(state => ({
        ...state,
        openNavigationDrawer: true
      })),
    closeNavigationDrawer: () =>
      setUiState(state => ({
        ...state,
        openNavigationDrawer: false
      }))
  };

  return (
    <UiContext.Provider value={[uiState, actions]}>
      {children}
    </UiContext.Provider>
  );
};

/**
 * @returns {[State, Actions]}
 */
export const useUiContext = () => useContext(UiContext);
