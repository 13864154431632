import React from 'react';
import { Typography, Button, useTheme } from '@material-ui/core';

const ResultSuccessStep = ({ hasSpec, next }) => {
  const [disabled, setDisabled] = React.useState(false);
  /**
   * @type {import('@material-ui/core').Theme}
   */
  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography>
        The pull request was merged.{' '}
        {hasSpec
          ? 'We found your achitecture definition.'
          : 'Add the architecture specification to continue.'}
      </Typography>
      <div>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
          onClick={async () => {
            setDisabled(true);
            await next().finally(() => setDisabled(false));
          }}
        >
          Continue
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ResultSuccessStep;
