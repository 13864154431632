import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';

import person from './shapes/person';
import item from './shapes/item';
import { renderLabel as renderItemLabel } from './shapes/TooltipLabel';
import { navigate } from '@reach/router';
import { teamSoftwareItemUrl } from '../../../utils/urls';

const ACTORS = 'actors';
const EXTERNALS = 'externals';

export default class Backend {
  constructor(opts) {
    this.opts = opts;
  }

  isExternal(id) {
    return this.spec.elements.some(
      element => element.id === id && element.location === 'external'
    );
  }

  isParent(id) {
    return this.spec.elements.some(element => element.parent === id);
  }

  removeOutput() {
    const output = d3.select('.output');

    output && output.remove();
  }

  setHostElement(element) {
    this.host = element;
  }

  update({ spec, zoom, interactive }) {
    if (!spec) return;

    const host = this.host;
    this.spec = spec;

    const g = new dagreD3.graphlib.Graph({
      compound: true
    }).setGraph({
      rankdir: 'TB'
    });

    g.setDefaultEdgeLabel('');
    g.setDefaultNodeLabel('');

    spec.elements
      .filter(element => element.type === 'person')
      .forEach(element => {
        g.setNode(element.id, {
          clusterLabelPos: 'top',
          external: element.location === 'external',
          name: element.name,
          id: element.id,
          description: element.description,
          label: renderItemLabel({
            id: element.id,
            title: element.description || '',
            name: element.name
          }),
          labelType: 'html',
          shape: 'person'
        });

        if (!g.hasNode(ACTORS)) {
          g.setNode(ACTORS, {
            shape: 'item',
            style: 'fill: transparent; stroke: transparent'
          });
        }

        g.setParent(element.id, ACTORS);
      });

    spec.elements
      .filter(element => element.type !== 'person')
      .forEach(element => {
        if (this.isParent(element.id)) {
          g.setNode(element.id, {
            clusterLabelPos: 'top',
            external: element.location === 'external',
            label: `
              <div class="architecture-viewer__name">${element.name}</div>
              <div class="architecture-viewer__type">«${element.type}»</div>
            `,
            labelType: 'html',
            shape: 'item',
            style: 'fill: whitesmoke; stroke: #333; stroke-dasharray: 8, 8;'
          });
        } else {
          // leaf item
          g.setNode(element.id, {
            onClick: interactive
              ? () =>
                  navigate(teamSoftwareItemUrl(this.opts.teamId, element.id))
              : undefined,
            callbacks: this.opts.callbacks,
            name: element.name,
            class: element.class,
            id: element.id,
            description: element.description,
            external: element.location === 'external',
            label: renderItemLabel({
              id: element.id,
              title: element.description || '',
              name: element.name
            }),
            labelType: 'html',
            shape: 'item'
          });
        }
      });

    spec.elements.forEach(element => {
      if (element.parent) g.setParent(element.id, element.parent);
    });

    spec.elements
      .filter(
        element => element.type === 'system' && element.location === 'external'
      )
      .forEach(element => {
        if (g.hasNode(EXTERNALS)) {
          g.setNode(EXTERNALS, {
            shape: 'item',
            style: 'fill: transparent; stroke: transparent'
          });
        }

        g.setParent(element.id, EXTERNALS);
      });

    spec.relationships.forEach(relationship => {
      if (
        g.hasNode(relationship.source) &&
        g.children(relationship.source).length === 0 &&
        g.hasNode(relationship.destination) &&
        g.children(relationship.destination).length === 0
      ) {
        g.setEdge(relationship.source, relationship.destination, {
          arrowhead: 'vee',
          curve: d3.curveBasis,
          label: relationship.description.replace(/\s/g, '<br>'),
          labelStyle: 'font-family: Arial, Helvetica, sans-serif;',
          labelType: 'html',
          style:
            'stroke: #333; fill: none; stroke-dasharray: 5, 5;stroke-width: 1px;'
          // minlen: this.isExternal(relationship.destination) ? 2 : 1
        });
      }
    });

    const svg = d3.select(host);

    const render = new dagreD3.render();

    render.shapes().item = item;
    render.shapes().person = person;

    render(svg, g);

    const inner = d3.select('.output');
    const zoomHandler = d3.zoom().on('zoom', () => {
      inner.attr('transform', d3.event.transform);
    });

    if (zoom) {
      svg.call(zoomHandler);
    }

    const viewWidth = host.parentNode.getBoundingClientRect().width;

    const scale = Math.min(
      (viewWidth - 2 * this.opts.padding) /
        (g.graph().width + 2 * this.opts.borderWidth),
      1
    );

    // const viewHeight = element.parentNode.getBoundingClientRect().height;
    const viewHeight = Math.max(
      g.graph().height * scale + 2 * this.opts.padding + 40,
      350
    );

    const translateWidth =
      (viewWidth - (g.graph().width + 2 * this.opts.borderWidth) * scale) / 2;

    const translateHeight =
      (viewHeight - (g.graph().height + 2 * this.opts.borderWidth) * scale) /
        2 +
      10;

    svg.attr('width', viewWidth);
    svg.attr('height', viewHeight);

    svg.call(
      zoomHandler.transform,
      d3.zoomIdentity.translate(translateWidth, translateHeight).scale(scale)
    );
  }
}
