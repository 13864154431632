import React from 'react';
import {
  Grid,
  Typography,
  Tooltip,
  Link,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiHelpCircle } from '@mdi/js';
import semver from 'semver';

/**
 *
 * @param {import('@material-ui/core/styles').Theme} theme
 */
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: '1px solid gainsboro',
    marginBottom: theme.spacing(2),
  },
  inline: {
    display: 'inline',
    lineHeight: 'inherit',
  },
  helpIcon: {},
});

const PackageInfo = ({ dep, spec, registryUrl }) => {
  const theme = useTheme();
  const classes = makeStyles(styles)(theme);

  console.log(dep, spec.soups);

  // TODO: needs a way to collect info about stdlibs
  if (spec.soups[dep] === undefined) return null;

  return (
    <Grid className={classes.root} container direction="column">
      <Grid item>
        <Typography variant="h6" className={classes.header}>
          Package information
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Description: {spec.soups && spec.soups[dep].description}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          License: {spec.soups && spec.soups[dep].license}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignContent="center" alignItems="center">
          <Grid item>
            <Typography
              className={classes.inline}
              style={{
                marginRight: theme.spacing(0.5),
              }}
            >
              Version: {spec.manifest.dependencies[dep]}
            </Typography>
          </Grid>
          {semver.validRange(spec.manifest.dependencies[dep]) && (
            <Grid item>
              <Typography>
                <Tooltip
                  title={semver.validRange(spec.manifest.dependencies[dep])}
                  placement="top-start"
                >
                  <Icon size={0.6} path={mdiHelpCircle} color="gray" />
                </Tooltip>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          Registry:{' '}
          <Link href={registryUrl} target="npm">
            {registryUrl}
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Homepage:{' '}
          <Link href={spec.soups && spec.soups[dep].homepage} target="_blanc">
            {spec.soups && spec.soups[dep].homepage}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PackageInfo;
