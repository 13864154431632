import React from 'react';
import {
  Card,
  CardHeader,
  CardActions,
  Button,
  Tooltip
} from '@material-ui/core';

const Uninstall = ({ classes, href }) => {
  return (
    <Card className={classes.card}>
      <CardHeader
        title="Danger Zone"
        titleTypographyProps={{ color: 'secondary' }}
      />
      <CardActions>
        <Tooltip title="Perform action in GitHub">
          <Button
            color="secondary"
            href={href}
            target="compliancepal"
            disabled={href === undefined}
          >
            Uninstall
          </Button>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default Uninstall;
