import React, { useEffect, useState } from 'react';

import Page from '../../components/Page2';
import Icon from '@mdi/react';
import { mdiCheck, mdiOpenInNew } from '@mdi/js';

import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  withStyles,
  useTheme,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { firestore } from '../../stores/firebaseInit';
import { collections } from '../../utils/db';
import { Branch, CommitDate } from '../SoftwareItemSoupCheck/CheckViewCard';
import Package from '../../components/viewers/Package';
import RiskManagementActions from './RiskManagementActions';
import { useInstallation } from '../../stores/InstallationProvider';
import { useSnackbar } from '../../stores/SnackbarProvider';
import PageHeader from '../../components/PageHeader';
import ReactTimeago from 'react-timeago';
import { navigate } from '@reach/router';

const isSuccessful = (changes, handled) => {
  const _changes =
    changes.added.length + changes.modified.length + changes.removed.length;

  if (handled === undefined && _changes >= 0) return false;

  return _changes === Object.keys(handled).length;
};

const styles = (theme) => ({
  summaryCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentHeader: {
    paddingBottom: theme.spacing(1),
  },
});

const tabs = ['Overview', 'Safety classification', 'Commits'];

const SoftwareItemPull = ({
  tabValue,
  classes,
  installationId,
  softwareItemId,
  pullId,
}) => {
  const theme = useTheme();
  const [installation, actions] = useInstallation();
  const snackbar = useSnackbar();
  const [pull, setPull] = useState(null);

  actions.init(installationId);

  useEffect(() => {
    const disposer = firestore
      .collection(collections.INSTALLATIONS)
      .doc(installationId)
      .collection(collections.SOFTWARE_ITEMS)
      .doc(softwareItemId)
      .collection(collections.PULLS)
      .doc(pullId)
      .onSnapshot((snap) => {
        console.log(snap.data());

        setPull(snap.data());
      });

    return () => disposer();
  }, [installationId, pullId, softwareItemId]);

  return (
    <Page
      team={installationId}
      title={`Software item ${softwareItemId} pull ${pullId} - CompliancePal console`}
    >
      <PageHeader
        title={`Software items - ${softwareItemId} - ${
          pull ? pull.title : '...'
        }`}
        tabs={tabs}
        value={tabValue}
      />
      {pull && installation && installation.data && (
        <Container>
          {tabValue === 0 && (
            <Card className={classes.summaryCard}>
              <CardHeader
                title={
                  <Grid container>
                    <Typography
                      variant="h5"
                      style={{
                        marginRight: theme.spacing(1),
                      }}
                    >
                      {pull.title}
                    </Typography>
                    {isSuccessful(
                      pull.spec.changes,
                      pull.handledChangedDeps
                    ) && (
                      <Tooltip title="Safety classification activity completed successfuly.">
                        <Typography>
                          <Icon
                            size={1}
                            path={mdiCheck}
                            color={green[500]}
                          ></Icon>
                        </Typography>
                      </Tooltip>
                    )}
                  </Grid>
                }
                subheader="Dependencies risk analysis"
              ></CardHeader>
              <CardContent>
                <Grid
                  container
                  alignItems="center"
                  className={classes.contentHeader}
                >
                  <Branch name={pull.ref} />
                  <Typography style={{ flexGrow: 1 }}></Typography>
                  <CommitDate
                    completed_at={pull.updated_at || pull.created_at}
                  />
                </Grid>
                <Package
                  key={Date.now()}
                  spec={pull.spec.data}
                  manifest={pull.spec.manifest}
                  changes={pull.spec.changes}
                  name={pull.spec.manifest.name || softwareItemId}
                  language={
                    installation.data.elements[softwareItemId].configuration
                      .language
                  }
                  onClickPackage={(name) => navigate(`?tab=1#${name}`)}
                  compact
                  diff
                ></Package>
              </CardContent>
            </Card>
          )}
          {tabValue === 1 && (
            <React.Fragment>
              <div style={{ marginTop: theme.spacing(2) }} />
              <RiskManagementActions
                merged={pull.merged}
                spec={pull.spec}
                handledDeps={pull.handledChangedDeps}
                pullUrl={pull._links.html.href}
                language={
                  installation.data.elements[softwareItemId].configuration
                    .language
                }
                addAction={({
                  adrDescription,
                  safetyClass,
                  softwareItemDescription,
                  softwareItemName,
                }) =>
                  actions
                    .addSoftwareItemDraft({
                      adrDescription,
                      commit: pull.lastCommitMeta.head_sha,
                      installationId,
                      parentId: softwareItemId,
                      pullNumber: pullId,
                      ref: pull.lastCommitMeta.ref,
                      safetyClass,
                      softwareItemDescription,
                      softwareItemName,
                    })
                    .then(() => {
                      snackbar.showMessage('Software item added');

                      return Promise.resolve();
                    })
                }
                modifyAction={({ adrId, description, safetyClass }) =>
                  actions
                    .supersedAdr({
                      adrId,
                      commit: pull.lastCommitMeta.head_sha,
                      description,
                      installationId,
                      pullNumber: pullId,
                      ref: pull.lastCommitMeta.ref,
                      safetyClass,
                    })
                    .then(() => {
                      snackbar.showMessage('Software item updated');

                      return Promise.resolve();
                    })
                }
                removeAction={({ adrId }) =>
                  actions
                    .deprecateAdr({
                      adrId,
                      commit: pull.lastCommitMeta.head_sha,
                      installationId,
                      pullNumber: pullId,
                      softwareItemId,
                    })
                    .then(() => {
                      snackbar.showMessage('Software item removed');

                      return Promise.resolve();
                    })
                }
              ></RiskManagementActions>
            </React.Fragment>
          )}
          {tabValue === 2 && (
            <Card>
              <CardContent>
                <List>
                  {pull.commits.map((commit, index) => (
                    <ListItem divider key={index}>
                      <ListItemText
                        primary={commit.message}
                        secondary={<ReactTimeago date={commit.pushed_at} />}
                      ></ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton href={commit.url} target="github">
                          <Icon size={1} path={mdiOpenInNew} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          )}
        </Container>
      )}
    </Page>
  );
};

export default withStyles(styles)(SoftwareItemPull);
