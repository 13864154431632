import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Link,
  IconButton,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import TimeAgo from 'react-timeago';

import CalendarBlankOutlineIcon from 'mdi-material-ui/CalendarBlankOutline';
import RestartIcon from 'mdi-material-ui/Restart';
import SourceBranchIcon from 'mdi-material-ui/SourceBranch';
import SourceCommitIcon from 'mdi-material-ui/SourceCommit';

import Package from '../../components/viewers/Package';

/**
 *
 * @param {object} options
 * @param {string} options.name
 */
export const Branch = ({ name }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <SourceBranchIcon fontSize="small" />
      <Typography style={{ marginRight: theme.spacing(1) }}>
        {name.replace('refs/heads/', '')}
      </Typography>
    </React.Fragment>
  );
};

/**
 *
 * @param {object} object
 * @param {object} object.classes
 * @param {boolean} object.flexGrow
 * @param {string} object.href
 * @param {string} object.sha
 */
const CommitSha = ({ classes, flexGrow, href, sha }) => {
  return (
    <React.Fragment>
      <SourceCommitIcon fontSize="small" className={classes.turn90} />
      <Typography
        className={classes.space}
        style={{ flexGrow: flexGrow ? 1 : 0 }}
      >
        <Link href={href} target="_blank">
          {sha}
        </Link>
      </Typography>
    </React.Fragment>
  );
};

/**
 *
 * @param {object} options
 * @param {string} options.completed_at
 */
export const CommitDate = ({ completed_at }) => {
  return (
    <React.Fragment>
      <CalendarBlankOutlineIcon fontSize="small" />
      <Typography>
        <TimeAgo date={completed_at} />
      </Typography>
    </React.Fragment>
  );
};

export const CheckViewerCard = ({
  classes,
  completed_at,
  meta,
  recheckAction,
  spec,
  sha,
  onClickPackage = (nodeName) => {
    window.location.assign(`#${nodeName}`);
  },
}) => {
  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          meta.check.output ? meta.check.output.title : 'SOUP check result'
        }
        action={
          recheckAction ? (
            <Tooltip title="Recheck">
              <IconButton onClick={recheckAction}>
                <RestartIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <RestartIcon />
            </IconButton>
          )
        }
      />
      <CardContent key={completed_at}>
        <Grid container className={classes.branchInfo} alignItems="center">
          <Branch name={meta.check.ref} />
          <CommitSha
            classes={classes}
            href={meta.check.commit_url}
            sha={sha}
            flexGrow
          ></CommitSha>
          <CommitDate completed_at={completed_at} />
        </Grid>
        <Package
          spec={spec.data}
          manifest={spec.manifest}
          changes={spec.changes}
          onClickPackage={onClickPackage}
          compact
          diff
        />
      </CardContent>
    </Card>
  );
};

export default CheckViewerCard;
