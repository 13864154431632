import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip
} from '@material-ui/core';
import SettingsIcon from 'mdi-material-ui/GithubCircle';
import { useInstallation } from '../../stores/InstallationProvider';
import { useState } from 'react';
import { teamArchitectureUrl, teamSoftwareItemUrl } from '../../utils/urls';
import { Link } from '@reach/router';

const Repositories = ({ classes }) => {
  const [state, setState] = useState(null);
  const [installation] = useInstallation();

  useEffect(() => {
    if (installation.data) {
      const roles = {};

      try {
        roles[installation.data.architecture.configuration.repo] = {
          title: 'architecture',
          href: teamArchitectureUrl(installation.id)
        };
      } catch (e) {
        console.log('architecture not configured');
      }

      try {
        Object.keys(installation.data.elements).forEach(item => {
          roles[installation.data.elements[item].configuration.repo] = {
            title: item,
            href: teamSoftwareItemUrl(installation.id, item)
          };
        });
      } catch (e) {
        console.log(e);
      }

      setState({
        href: installation.data.meta.html_url,
        repos: installation.data.repositories.map(repo => ({
          full_name: repo.full_name,
          private: repo.private
        })),
        roles
      });
    }
  }, [installation]);

  if (!state) return null;

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Repositories"
        subheader="GitHub resources"
        action={
          <Tooltip title="Manage repositories in GitHub installation page">
            <IconButton href={state.href} target="compliancepal">
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Visibility</TableCell>
              <TableCell>Repository</TableCell>
              <TableCell>Linked software item</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.repos.map(repo => (
              <TableRow key={repo.full_name}>
                <TableCell padding="checkbox">
                  {repo.private ? 'yes' : 'no'}
                </TableCell>
                <TableCell>{repo.full_name}</TableCell>
                <TableCell>
                  <Link to={state.roles[repo.full_name].href}>
                    {state.roles[repo.full_name].title}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Repositories;
