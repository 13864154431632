import React, { useState, useEffect } from 'react';
import Page from '../components/Page2';
import { firestore } from '../stores/firebaseInit';
import { Container } from '@material-ui/core';
import { DecisionRecordsCard } from './SoftwareItem/DecisionRecordsCard';
import PageHeader from '../components/PageHeader';

const TechnicalReports = ({ installationId }) => {
  const [adrs, setAdrs] = useState([]);
  const [, setLoading] = useState(true);

  useEffect(() => {
    return firestore
      .collection('installations')
      .doc(installationId)
      .collection('adrs')
      .onSnapshot((snap) => {
        setLoading(false);
        setAdrs(
          snap.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  }, [installationId]);

  return (
    <Page team={installationId} title="Technical files - CompliancePal console">
      <PageHeader title="Technical files" tabs={['Catalog']}></PageHeader>
      <Container>
        <DecisionRecordsCard
          adrs={adrs}
          installationId={installationId}
          filtered
          parent
        />
      </Container>
    </Page>
  );
};

export default TechnicalReports;
