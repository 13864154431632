import React from 'react';
import { Typography, useTheme, makeStyles } from '@material-ui/core';

// @ts-ignore
import { green, red, amber } from '@material-ui/core/colors';

/**
 * @callback themeFn
 * @param {import('@material-ui/core').Theme} theme
 */

/**
 *
 * @param {import('@material-ui/core').Theme} theme
 * @param {*} color
 */
// @ts-ignore
const getStyle = (theme, color) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  backgroundColor: color,
  color: theme.palette.getContrastText(color),
  textAlign: 'center'
});

/**
 *
 * @param {import('@material-ui/core').Theme} theme
 */
const styles = theme => ({
  // @ts-ignore
  accepted: getStyle(theme, green[600]),
  // @ts-ignore
  deprecated: getStyle(theme, red[600]),
  // @ts-ignore
  // proposed: getStyle(theme, 'gray'),
  // @ts-ignore
  superseeded: getStyle(theme, amber[500]),
  superseded: getStyle(theme, amber[500])
});

const Status = ({ value }) => {
  const theme = useTheme();
  const classes = makeStyles(styles)(theme);

  return (
    <Typography variant="caption" className={classes[value]}>
      {value === 'superseeded' ? 'superseded' : value}
    </Typography>
  );
};

export default Status;
