import React, { useState, useEffect } from 'react';
import { firestore } from '../../stores/firebaseInit';
import { DecisionRecordsCard } from './DecisionRecordsCard';

const DecisionRecords = ({ installationId, softwareItemId }) => {
  const [adrs, setAdrs] = useState([]);

  useEffect(() => {
    // if (adrs.length > 0) return;

    const unsubscribe = firestore
      .collection('installations')
      .doc(installationId)
      .collection('adrs')
      .where('parent', '==', softwareItemId)
      .onSnapshot(snap => {
        const _adrs = snap.docs
          .map(doc => ({
            id: doc.id,
            data: doc.data()
          }))
          .filter(adr => ['accepted'].includes(adr.data.status));
        setAdrs(_adrs);
      });

    return unsubscribe;
  }, [installationId, softwareItemId]);

  if (adrs.length === 0) return null;

  return (
    <DecisionRecordsCard
      adrs={adrs}
      installationId={installationId}
      subheader="Decisions incluencing the design of this software item"
    />
  );
};

export default DecisionRecords;
