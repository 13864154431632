import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from '@material-ui/core';

import AddIcon from 'mdi-material-ui/AccountPlus';
import RemoveIcon from 'mdi-material-ui/Close';
import { functions } from '../../stores/firebaseInit';
import { useSnackbar } from '../../stores/SnackbarProvider';

const TeamMembership = ({ classes, installation }) => {
  const snackbar = useSnackbar();

  const [state, setState] = useState({
    addInProgress: false,
    dialogOpen: false,
    username: '',
    hints: []
  });

  if (!installation) return null;

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Team"
        subheader="Manage collaborators"
        action={
          <IconButton
            disabled={state.addInProgress}
            onClick={() => {
              setState({
                ...state,
                dialogOpen: true
              });
            }}
          >
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        <List>
          {installation.team.members.map(member => (
            <React.Fragment key={member.id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={member.avatar_url} />
                </ListItemAvatar>
                <ListItemText
                  primary={member.name}
                  secondary={member.login}
                ></ListItemText>
                {/*<Typography
                  style={{
                    marginRight: 16,
                    paddingLeft: 8,
                    paddingRight: 8,
                    border: '1px solid lightgrey',
                    borderRadius: 5
                  }}
                >
                  {installation.__abac.owners[auth.currentUser.uid]
                    ? 'owner'
                    : 'member'}
                  </Typography>*/}
                <ListItemSecondaryAction>
                  <IconButton edge="end" disabled>
                    <RemoveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider></Divider>
            </React.Fragment>
          ))}
        </List>
        <Dialog open={state.dialogOpen}>
          <DialogTitle id="add-team-dialog-title">New team member</DialogTitle>
          <DialogContent>
            <DialogContentText id="add-team-dialog-description">
              Add GitHub user to your team.
            </DialogContentText>
            <TextField
              label="username"
              value={state.username}
              margin="normal"
              fullWidth
              onChange={event =>
                setState({
                  ...state,
                  username: event.target.value
                })
              }
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  this.add();
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={state.addInProgress}
              onClick={() => {
                setState({
                  ...state,
                  dialogOpen: false,
                  username: ''
                });
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={state.addInProgress}
              color="primary"
              onClick={async () => {
                setState({
                  ...state,
                  addInProgress: true
                  // dialogOpen: false
                });

                console.log('add user to installation', {
                  installation_id: installation.meta.installation.id,
                  username: state.username
                });

                await functions
                  .httpsCallable('httpsOnCallTeamMemberAdd')({
                    installation_id: installation.meta.installation.id,
                    username: state.username
                  })
                  .then(response => {
                    console.log(response);
                    snackbar.showMessage('User added to the team');
                  })
                  .catch(error => {
                    console.log(error);
                    snackbar.showMessage('There was an error...');
                  })
                  .finally(() => {
                    setState({
                      ...state,
                      addInProgress: false,
                      dialogOpen: false,
                      username: ''
                    });
                  });
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default TeamMembership;
