const d3 = require('d3');
const dagreD3 = require('dagre-d3');

const componentShape = require('./component');

/*
const style = cls => {
  switch (cls.toUpperCase()) {
    case 'A':
      return '';
    case 'B':
      return 'fill: url(#smalldot)';
    case 'C':
      return 'fill: url(#dots-1)';
    default:
      throw new Error('Invalid safety class');
  }
};
*/

const insertComponents = (parent, components) => {
  const content = parent.insert('g').classed('content', true);

  const g = new dagreD3.graphlib.Graph({}).setGraph({
    // rankdir: 'LR'
  });

  components.elements.forEach(element => {
    g.setNode(element.id, {
      label: `<div style="text-align: center;">${element.id}</div><div style="text-align: center;">«class ${element.class}»</div>`,
      labelType: 'html',
      shape: 'component'
      // style: style(element.class)
    });
  });

  components.relationships.forEach(relationship => {
    g.setEdge(relationship.source, relationship.destination, {
      label: relationship.description,
      arrowhead: 'vee',
      curve: d3.curveBasis
    });
  });

  const render = new dagreD3.render();

  render.shapes().component = componentShape;

  render(content, g);
};

const shape = (parent, bbox, node) => {
  const container = parent.insert('g');

  insertComponents(container, node.boundaries);

  const { width, height } = node.elem.getBoundingClientRect();
  const arrowOffset = 0;

  container.attr(
    'transform',
    'translate(' + [-width / 2, -height / 2 - arrowOffset] + ')'
  );

  const hPadding = 40;
  const vPadding = 60;
  const decorationHeight = 10;

  container
    .insert('rect', ':first-child')
    .attr('width', width + hPadding)
    .attr('height', height + vPadding)
    .attr('transform', 'translate(' + [-hPadding / 2, -vPadding / 2] + ')');

  container
    .insert('rect', ':first-child')
    .attr('width', 30)
    .attr('height', decorationHeight)
    .attr(
      'transform',
      'translate(' + [-hPadding / 2, -vPadding / 2 - decorationHeight] + ')'
    );

  container
    .append('text', ':first-child')
    .attr('text-anchor', 'middle')
    .attr('transform', 'translate(' + [width / 2, -decorationHeight] + ')')
    // .attr('transform', 'translate(' + [0, -10] + ')')
    .text(node.label);

  node.intersect = point => dagreD3.intersect.rect(node, point);

  return parent;
};

module.exports = shape;
