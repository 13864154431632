import React from 'react';

import Landing from '../pages/Landing';
import { useProfile } from '../stores/profileProvider';
import { analytics } from '../stores/firebaseInit';
import { useEffect } from 'react';
import { useState } from 'react';
import { parse } from 'query-string';

export const getTab = () => {
  let tab = 0;
  try {
    const parsed = parse(window.location.search);

    tab = parsed.tab ? parseInt(parsed.tab) : 0;
  } catch (e) {
    console.log(e);
  } finally {
    return tab;
  }
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [profile] = useProfile();
  const [tab, setTab] = useState(0);

  const { location } = rest;

  useEffect(() => {
    setTab(getTab());
  }, [location]);

  if (profile) {
    console.log('rendering page...', rest.uri);

    analytics.logEvent('page_view', {
      page_path: rest.uri
    });

    return <Component tabValue={tab} {...rest} />;
  }

  console.log('redirecting...', rest);
  // FIXME: not working properly
  return <Landing></Landing>;
};

export default ProtectedRoute;
