import React, { useState, useEffect } from 'react';
import {
  Typography,
  Link,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import TimeAgo from 'react-timeago';
import { navigate } from '@reach/router';
import { teamSoftwareItemPullUrl } from '../../utils/urls';
import { mdiFileCheckOutline, mdiSourcePull, mdiSourceMerge } from '@mdi/js';
import Icon from '@mdi/react';
import { firestore } from '../../stores/firebaseInit';
import { collections } from '../../utils/db';
import { green, grey } from '@material-ui/core/colors';

const useStyles = (theme) => ({
  pull: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '&:hover': {
      backgroundColor: 'whitesmoke',
    },
  },
});

export const PullsList = ({ installationId, softwareItemId, state }) => {
  const theme = useTheme();
  const classes = makeStyles(useStyles)(theme);
  const [pulls, setPulls] = useState([]);

  useEffect(() => {
    const disposer = firestore
      .collection(collections.INSTALLATIONS)
      .doc(installationId)
      .collection(collections.SOFTWARE_ITEMS)
      .doc(softwareItemId)
      .collection(collections.PULLS)
      .where('state', '==', state)
      .onSnapshot((snap) => {
        const pulls = snap.docs
          .map((pull) => ({
            id: pull.id,
            data: pull.data(),
          }))
          .reverse();

        setPulls(pulls);
      });

    return () => disposer();
  }, [installationId, softwareItemId, state]);

  return (
    <React.Fragment>
      {pulls.length === 0 && (
        <Typography>No open pull requests at this time</Typography>
      )}
      <List>
        {pulls.map((pull) => {
          console.log(pull);
          const path = state === 'open' ? mdiSourcePull : mdiSourceMerge;

          return (
            <ListItem key={pull.id} dense className={classes.pull}>
              <ListItemAvatar>
                <Tooltip
                  placement="right"
                  title={
                    state === 'open'
                      ? 'Open pull request'
                      : 'Merged pull request'
                  }
                >
                  <Icon
                    size={1}
                    path={path}
                    color={state === 'open' ? grey[500] : green[500]}
                  />
                </Tooltip>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle2">{pull.data.title}</Typography>
                }
                secondary={
                  <React.Fragment>
                    <Link href={pull.data._links.html.href} target="github">
                      <Typography variant="body2" component="span">
                        #{pull.id}
                      </Typography>
                    </Link>
                    {state === 'open' ? (
                      <Typography component="span" variant="body2">
                        {' '}
                        was opened{' '}
                        <TimeAgo date={pull.data.created_at}></TimeAgo>
                      </Typography>
                    ) : (
                      <Typography component="span" variant="body2">
                        {' '}
                        was merged{' '}
                        <TimeAgo date={pull.data.closed_at}></TimeAgo>
                      </Typography>
                    )}
                  </React.Fragment>
                }
              />
              <ListItemIcon>
                <Tooltip title="View report">
                  <IconButton
                    edge="end"
                    onClick={() =>
                      navigate(
                        teamSoftwareItemPullUrl(
                          installationId,
                          softwareItemId,
                          pull.id
                        )
                      )
                    }
                  >
                    <Icon size={1} path={mdiFileCheckOutline} />
                  </IconButton>
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};
