import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from '@reach/router';

import {
  Container,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  CardHeader
} from '@material-ui/core';
import { firestore, functions } from '../../stores/firebaseInit';
import { SelectRepoStep } from './SelectRepoStep';
import ResultCancelledStep from './ResultCancelledStep';
import ViewPullRequestStep from './ViewPullRequestStep';
import Page from '../../components/Page2';
import { teamSoftwareUrl } from '../../utils/urls';
import ResultSuccessStep from './ResultSuccessStep';
import PageHeader from '../../components/PageHeader';

const initialState = {
  activeStep: -1,
  loading: true,
  installation: null
};

const reducer = (state, action) => {
  const result = { ...state };

  switch (action.type) {
    case 'installationData':
      result.installation = action.payload;
      result.loading = false;

      if (result.installation.hasOwnProperty('architecture')) {
        if (result.installation.architecture.hasOwnProperty('configuration')) {
          switch (result.installation.architecture.configuration.state) {
            case 'cancelled':
              result.activeStep = 2;
              result.error = 2;
              break;
            case 'started':
            case 'pending':
              result.activeStep = 1;
              break;
            case 'completed':
              result.activeStep = 2;
              break;
            default:
              throw new Error('invalid configuration state');
          }
        }
      } else {
        result.activeStep = 0;
      }

      return result;
    case 'restart':
      result.activeStep = 0;
      delete result.error;

      return result;
    case 'loading_start':
      result.loading = true;

      return result;
    case 'loading_end':
      result.loading = false;

      return result;
    default:
      throw new Error('Unknown action');
  }
};

const getSteps = () => {
  return [
    'Select repository location',
    'Review pull request on GitHub',
    'Result'
  ];
};

const ArchitectureSetup = ({ installation_id }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const unsubscribe = firestore
      .collection('installations')
      .doc(installation_id.toString())
      .onSnapshot(doc => {
        dispatch({ type: 'installationData', payload: doc.data() });
      });

    return () => unsubscribe();
  }, [installation_id]);

  const steps = getSteps();

  const getStepContent = step => {
    switch (step) {
      case 0:
        return state.installation ? (
          <SelectRepoStep
            installation_id={installation_id}
            repositories={state.installation.repositories}
            next={async selected => {
              dispatch({ type: 'loading_start' });

              const [owner, repo] = state.installation.repositories[
                selected
              ].full_name.split('/');

              await functions
                .httpsCallable('httpsOnCallArchitectureSetup')({
                  installation_id,
                  owner,
                  repo
                })
                .finally(() => {
                  dispatch({ type: 'loading_end' });
                });
            }}
          />
        ) : (
          <div>Loading</div>
        );
      case 1:
        return state.installation &&
          state.installation.architecture &&
          state.installation.architecture.configuration.state === 'pending' ? (
          <ViewPullRequestStep
            href={
              state.installation.architecture.configuration.pull_request.href
            }
          ></ViewPullRequestStep>
        ) : null;
      case 2:
        if (!(state.installation && state.installation.architecture))
          return null;

        if (
          state.installation.architecture.configuration.state === 'cancelled'
        ) {
          return (
            <ResultCancelledStep
              cancel={async () => {
                dispatch({ type: 'loading_start' });

                await functions
                  .httpsCallable('httpsOnCallArchitectureSetupCancel')({
                    installation_id
                  })
                  .finally(() => {
                    dispatch({ type: 'loading_end' });
                  });

                navigate(teamSoftwareUrl(installation_id));
              }}
              restart={async () => {
                dispatch({ type: 'loading_start' });

                await functions
                  .httpsCallable('httpsOnCallArchitectureSetupCancel')({
                    installation_id
                  })
                  .finally(() => {
                    dispatch({ type: 'loading_end' });
                  });

                dispatch({ type: 'restart' });
              }}
            ></ResultCancelledStep>
          );
        }

        if (
          state.installation.architecture.configuration.state === 'completed'
        ) {
          console.log(state.installation.architecture);
          return (
            <ResultSuccessStep
              next={async () => {
                navigate(teamSoftwareUrl(installation_id));
              }}
              hasSpec={state.installation.architecture.hasOwnProperty('spec')}
            ></ResultSuccessStep>
          );
        }

        return null;
      default:
        return (
          <React.Fragment>
            <Typography>Unknown step</Typography>
          </React.Fragment>
        );
    }
  };

  console.log(state);

  return (
    <Page team={installation_id}>
      <Helmet>
        <title>CompliancePal: Architecture location setup</title>
      </Helmet>
      <PageHeader title="Architecture" tabs={['Configuration']} />
      <Container>
        <Card>
          <CardHeader title="Configure architecture location"></CardHeader>
          <CardContent>
            <Stepper activeStep={state.activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel error={state.error === index}>{label}</StepLabel>
                  <StepContent>{getStepContent(index)}</StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ArchitectureSetup;
